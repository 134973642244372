import React, { useContext } from "react"
import { FaExclamationTriangle } from "react-icons/fa"
import { Link, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import Links from "../components/Links"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/error"

export default function ErrorServer() {
  const [searchParams] = useSearchParams()
  const message = searchParams.get("message")
    const { langKey,langHtml } = useContext(ShopContext)
    const loc =  locales[langKey]
  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title_server}</title>
        <meta name="description" content="Ошибка на сторонк сервера" />
      </Helmet>
      <Links home={true} />
      <div className="flex flex-col items-center justify-center h-full gap-2 text-xl">
        <FaExclamationTriangle className="text-7xl text-red-500" />
        <p>{message}</p>
        <Link className="text-blue-500 cursor-pointer" to="/">
          {loc.backHome}
        </Link>
      </div>
    </>
  )
}
