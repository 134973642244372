import { NOIMAGE } from "../config/index"
import { useLoaderData, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { getCurrencySymbol, getPriceForShow, getShortDescription, localStorageService, ucFirst } from "../utils/index"
import Links from "../components/Links"
import { FaBorderAll, FaList, FaPencilAlt, FaTimes } from "react-icons/fa"
import { useCurrencyRate, useProductsByCatalogId } from "../swr"
import { toast } from "react-toastify"
import HandleConfirm from "../components/HandleConfirm"
import AuthContext from "../context/AuthContext"
import { useSWRConfig } from "swr"
import BrandContext from "../context/BrandContext"
import { Helmet } from "react-helmet-async"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/catalog"
import Spinner from "../components/Spinner"
import Pagination from "../components/Pagination"
import Loupe from "../components/Loupe"

export default function Catalog() {
  const API_URL = process.env.REACT_APP_API_URL
  const catalog = useLoaderData()

  const [isShowAsList, setIsShowAsList] = useState(() => {
    const data = localStorageService.get("catalog")
    return data?.isShowAsList ?? false
  })
  const [isShow, setIsShow] = useState(false)
  const [image, setImage] = useState("")
  const [innerWidth, setInnerWidth] = useState(0)
  const [productList, setProductList] = useState([])
  const [currentPage, setCurrentPage] = useState(() => {
    const data = localStorageService.get("catalog")
    return data?.[catalog._id] ?? 1
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { brands } = useContext(BrandContext)
  const { lang, setSlugFromPage, langKey, langUri, langHtml,currencyShop } =
    useContext(ShopContext)
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const { mutate } = useSWRConfig()
  const navigate = useNavigate()

  const currencyRate = useCurrencyRate()
  const products = useProductsByCatalogId({
    id: catalog._id,
    brands   
  })

  const loc = locales[langKey]

  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    if (products) {
      setProductList(
        products.sort((a, b) => (a.name[langKey] > b.name[langKey] ? 1 : -1))
      )
    }
  }, [products])

  useEffect(() => {
    setSlugFromPage(catalog.slug)
    return () => {
      setSlugFromPage(null)
    }
  }, [lang])

  useEffect(() => {
    localStorageService.update("catalog", { isShowAsList })
  }, [isShowAsList])

  const handleDelete = async (rez) => {
    if (rez) {
      if (!catalog.isLeaf) {
        toast.warning("Обнаружены подкаталоги. Сначала удалите их")
        return
      }
      if (catalog.haveProducts) {
        toast.warning("В этом каталоге есть товары")
        return
      }
      const res = await fetch(`/api/catalogs/catalog/${catalog._id}`, {
        method: "DELETE",
      })
      const data = await res.json()
      if (!res.ok) {
        toast.error(data.message)
        return
      }
      const brandIds = brands.map((item) => item._id)
      mutate({ url: `/api/catalogs/navbar`, swrData: { brandIds } })
      navigate(-1)
    }
    setShowDeleteModal(false)
  }

  const handlePageClick = (item) => {
    if (!catalog._id) return
    localStorageService.update("catalog", { [catalog._id]: item })
    setCurrentPage(item)
  }

  // pagination
  const productsPerPage = 12
  const idxOfLastProduct = currentPage * productsPerPage
  const idxOfFirstProduct = idxOfLastProduct - productsPerPage
  const productsOnPage = productList.slice(idxOfFirstProduct, idxOfLastProduct)

  const numbers = []
  for (let i = 1; i <= Math.ceil(productList.length / productsPerPage); i++) {
    numbers.push(i)
  }

  useEffect(() => {
    let data = localStorageService.get("catalog")
    // предохранитель если окажется что номер в localStorage больше чем есть страниц
    if (
      data &&
      data[catalog._id] &&
      productList.length &&
      numbers.length < data[catalog._id]
    ) {
      data = localStorageService.delete("catalog", catalog._id)
    }
    setCurrentPage(data?.[catalog._id] ?? 1)
  }, [catalog, productList.length, numbers.length])

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <link
          rel="alternate"
          hreflang="ru"
          href={`${API_URL}/catalog/${catalog.slug.ru}/${catalog._id}`}
        />
        <link
          rel="alternate"
          hreflang="uk"
          href={`${API_URL}/ua/catalog/${catalog.slug.ua}/${catalog._id}`}
        />
        <link
          rel="canonical"
          href={`${API_URL}${langUri}/catalog/${catalog.slug[langKey]}/${catalog._id}`}
        />
        <title>
          {`${ucFirst(
            catalog.crumbs.map((item) => item.name[langKey]).join(" ")
          )} - ${loc.title} `}
        </title>
        <meta
          name="description"
          content={`${loc.content_1}  ${catalog.crumbs
            .map((item) => item.name[langKey])
            .join(" ")
            .toLowerCase()} ${loc.content_2}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://www.schema.org",
            "@type": "catalog",
            name: ucFirst(
              catalog.crumbs.map((item) => item.name[langKey]).join(" ")
            ),
            image: `${API_URL}${catalog.image.md}`,
          })}
        </script>
      </Helmet>

      <Links home={true} back={true}>
        <div className="flex   justify-between">
          <div className="flex items-center gap-x-4">
            <div className=" flex flex-wrap text-gray-800">
              {catalog &&
                catalog.crumbs.map((item, i, arr) => (
                  <div className="flex text-base " key={i}>
                    <p>{item.name[langKey]}</p>
                    {i < arr.length - 1 ? <div className="px-1">➔</div> : null}
                  </div>
                ))}
            </div>
            {isAdmin && (
              <div className="hidden md:flex items-center gap-x-3">
                <FaPencilAlt
                  className="text-sb cursor-pointer text-xl"
                  onClick={() => navigate(`/edit_catalog/${catalog._id}`)}
                />
                <FaTimes
                  className="text-red-500 text-2xl cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            )}
          </div>

          <div
            className="flex justify-center items-center  gap-x-2 px-1
           text-2xl text-green-700"
          >
            <div
              className="cursor-pointer"
              title="Список"
              onClick={() => setIsShowAsList(true)}
            >
              <FaList />
            </div>
            <div
              className="cursor-pointer"
              title="Плитка"
              onClick={() => setIsShowAsList(false)}
            >
              <FaBorderAll />
            </div>
          </div>
        </div>
      </Links>

      <div className="flex flex-col gap-3 flex-1 pt-4">
        <div
          className="flex justify-center md:justify-start gap-4 h-[70px] items-center
              px-3 py-1 bg-neutral-100"
        >
          {catalog?.image?.sm ? (
           <img
            className="max-w-[100px] max-h-[66px]"
            src={catalog.image.sm}
            alt={catalog ? catalog.name[langKey] : ""}
          />
          ):null}
         
          <div className="">
            <h1 className="text-2xl lg:text-3xl text-amber-900 inline">
              {catalog.name[langKey]}{" "}
            </h1>
            <span className="text-gray-500 text-xl">{`(${productList.length} шт)`}</span>
          </div>
        </div>
        <div className="flex-1">        

 {/* Спинер круттся тогда когда products=null и productList пустой */}
      {!!products || !!productList.length ? (
        <>
          {isShowAsList ? (
            <div className="px-1">
              <table className="w-full border-collapse table-fixed text-base border border-gray-500">
                <thead className="border border-gray-500">
                  <tr>
                    <th className="w-[70px]">&nbsp;</th>
                    <th className="w-[180px]">{loc.name}</th>

                    <th className="w-auto hidden lg:table-cell">
                      {loc.description}
                    </th>
                    <th className="w-[100px]">{loc.price}</th>
                  </tr>
                </thead>
                <tbody className="border border-gray-500">
                  {productsOnPage.map((item, i) => (
                    <tr
                      className="border border-gray-500 hover:border-x-blue-500 hover:border-x
                     hover:cursor-pointer  group"
                      onClick={() =>
                        navigate(
                          `${langUri}/product/${item.slug[langKey]}/${item._id}`
                        )
                      }
                      key={i}
                    >
                      <td className=" text-center align-middle leading-4 border border-gray-500 peer">
                        <div
                          className=" inline-flex justify-center items-center p-0.5
                         h-[85px] w-[65px]"
                        >
                          <img
                            className="max-w-full max-h-full cursor-zoom-in active:scale-95"
                            src={
                              item.images.length
                                ? `${item.images[0].sm}`
                                : `${NOIMAGE}`
                            }
                            alt={item.name[langKey]}
                            onClick={
                              item.images.length
                                ? (e) => {
                                    e.stopPropagation()
                                    setIsShow(true)
                                    setImage(item.images[0].lg)
                                  }
                                : null
                            }
                          />
                        </div>
                      </td>
                      <td className="text-center border border-gray-500 px-1 text-gray-800 group-hover:text-red-500 peer-hover:text-gray-800">
                        <h3>{item.name[langKey]}</h3>
                      </td>

                      <td className="text-center border border-gray-500 px-1 hidden lg:table-cell">
                        {getShortDescription(
                          item.description[langKey],
                          innerWidth / 15
                        )}
                      </td>
                      <td className="text-center text-xl border border-gray-500 px-1">
                        {!!currencyRate && (
                          <div>
                            {getPriceForShow({
                              currencyRate,
                              currencyShop,
                              product: item,
                            })}{" "}
                            {getCurrencySymbol(currencyShop)}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
            </div>
          ) : (
            <div className="grid gap-1 grid-cols-2 auto-rows-auto sm_1:auto-rows-[520px]   sm_1:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm_1:gap-5 justify-items-center">
              {productsOnPage.map((item, i) => (
                <div
                  // className="flex flex-col gap-1 pt-1 items-center border border-gray-500 shadow-md w-[300px] h-[470px]
                  //  overflow-auto hover:cursor-pointer hover:border-blue-500"
                  className="grid grid-cols-[minmax(0,300px)] grid-rows-[minmax(0,1fr),auto,40px] sm_1:grid-rows-[minmax(0,1fr),minmax(50px,auto),50px]  
                  border border-gray-500 shadow-md hover:cursor-pointer hover:border-blue-500 group"
                  onClick={() =>
                    navigate(
                      `${langUri}/product/${item.slug[langKey]}/${item._id}`
                    )
                  }
                  key={i}
                >
                  <div className="p-1 flex items-center justify-center peer">
                    <img
                      className="max-w-full max-h-full  hover:cursor-zoom-in active:scale-95"
                      src={
                        item.images.length
                          ? `${item.images[0].md}`
                          : `${NOIMAGE}`
                      }
                      alt={item.name[langKey]}
                      onClick={
                        item.images.length
                          ? (e) => {
                              e.stopPropagation()
                              setIsShow(true)
                              setImage(item.images[0].lg)
                            }
                          : (e) => e.stopPropagation()
                      }
                    />
                  </div>
                  <h3
                    className="flex  items-center justify-center  border border-y-gray-500 text-gray-800 group-hover:text-red-500 peer-hover:text-gray-800  text-lg/5
                   sm_1:text-xl/6 p-1 indent-1 hyphens-auto"
                  >
                    {item.name[langKey]}
                  </h3>

                  <div className="  flex justify-center items-center bg-gray-200 text-xl sm_1:text-2xl ">
                    {currencyRate ? (
                      <p>
                        {getPriceForShow({
                          currencyRate,
                          currencyShop,
                          product: item,
                        })}{" "}
                        {getCurrencySymbol(currencyShop)}
                      </p>
                    ) : (
                      <p> </p>
                    )}
                  </div>
                </div>
              ))}

              {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            handlePageClick={handlePageClick}
            numbers={numbers}
          />
        </>
      ) : (
        <Spinner />
      )}

        </div>
        {showDeleteModal && (
          <HandleConfirm
            title1="Удалить каталог"
            title2={`${catalog.name.ru}?`}
            handle={handleDelete}
          />
        )}
      </div>
    </>
  )
}
