const locales = {
  ru: {
    title_client: "Ошибка клиента",
    title_server: "Ошибка сервера",
    backHome: "Вернуться на главную",
  },
  ua: {
    title_client: "Помилка клієнта",
    title_server: "Помилка сервера",
    backHome: "Повернутись на головну",
  },
}

export default locales
