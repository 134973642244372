const locales = {
  ru: {
    title: "Контакты магазина",
    content:
      "Адрес, телефоны, время работы, положение на карте магазина Кармен",
    shop_name: "Оптовый магазин Кармен",
    address: "Адрес магазина",
    phones: "Телефоны",
    hours: "Время работы",
  },
  ua: {
    title: "Контакти магазину",
    content: "Адреса, телефони, час роботи, положення на карті магазину Кармен",
    shop_name: "Оптовий магазин Кармен",
    address: "Адреса магазину",
    phones: "Телефони",
    hours: "Час роботи",
  },
}

export default locales
