const locales = {
  ru: {
    title: "Карта",
    content: "Местоположение на карте магазина Кармен",
  },
  ua: {
    title: "Мапа",
    content: "Місцезнаходження на мапі магазина Кармен",
  },
}

export default locales
