import React, { useContext } from "react"
import ClientPriceBlock from "./ClientPriceBlock"
import ShopContext from "../context/ShopContext"

export default function ClientOptionsPrice({
 optPrices,
  level,
  saveValues,
  product,
  crumbs,

}
 
) {
  const { lang }=useContext(ShopContext)
  const langKey = lang === "ua" ? "ua" : "ru"
  if (!level && Object.hasOwn(optPrices, "price")) {
    return (
      <div className="flex  justify-end">
        <ClientPriceBlock
          product={product}
          optPrices={optPrices}
          saveValues={saveValues}
        />
      </div>
    )
  } else {
    return (
      <div>
        {optPrices.map((optObj) => (
          <div key={`${level}${optObj.ru}${crumbs.join("")}`}>
            {optObj.add.hasOwnProperty("price") ? (
              <div className="flex justify-between items-center option pr-2 py-1 overflow-x-auto">
                <div
                  style={{
                    "--dynamic-padding": `${(level + 1) * 20}px`,
                    "--dynamic-padding-sm": `${(level + 1) * 10}px`,
                  }}
                  className="pl-[var(--dynamic-padding-sm)] sm:pl-[var(--dynamic-padding)]"
                >
                  {optObj[langKey]}
                </div>
                <ClientPriceBlock
                  product={product}
                  optPrices={optObj.add}
                  saveValues={saveValues}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    "--dynamic-padding": `${(level + 1) * 20}px`,
                    "--dynamic-padding-sm": `${(level + 1) * 10}px`,
                  }}
                  className=" option py-1 flex items-center pl-[var(--dynamic-padding-sm)] sm:pl-[var(--dynamic-padding)]"
                >
                  {optObj[langKey]}
                </div>

                <ClientOptionsPrice
                  level={level + 1}
                  product={product}
                  optPrices={optObj.add}
                  saveValues={saveValues}
                  crumbs={[...crumbs, optObj.ru]}
                />
              </>
            )}
          </div>
        ))}
      </div>
    )
  }
}
