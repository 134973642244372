import { Link } from "react-router-dom"
import { FaMapMarkerAlt } from "react-icons/fa"
import { useContext } from "react"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/footer"

export default function Footer() {
  const { langUri, langKey } = useContext(ShopContext)
  const loc = locales[langKey]

  return (
    <div className="h-full flex flex-col items-center justify-center gap-y-2 bg-[#333] text-[#fff]  text-center">
      <div className="w-full">
        <div className="flex  justify-around  text-xl max-w-[1500px] m-auto">
          <Link to={`${langUri}/information`}>
            <h2 className=" tracking-[0.3rem] hover:cursor-pointer">
              {loc.information}
            </h2>
          </Link>

          <Link to={`${langUri}/map`}>
            <div className=" hidden lg:flex items-center justify-center gap-x-2">
              <FaMapMarkerAlt />
              <h2>{loc.map}</h2>
            </div>
          </Link>
          <div>
            <div>
              <Link to={`${langUri}/contacts`}>
                <h2 className=" tracking-[0.3rem] hover:cursor-pointer">
                  {loc.contacts}
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-600 w-full"></div>
      <div className="  text-lg w-full">
        <p>Кармен &copy; 2021</p>
      </div>
    </div>
  )
}
