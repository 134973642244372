import React, { useContext } from "react"
import { useGallery, useShowcase } from "../swr"
import GallerySlider from "./GallerySlider"
import CardPopular from "./CardPopular"
import CardNews from "./CardNews"
import Spinner from "./Spinner"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/showcase"

export default function Showcase() {
  const products = useShowcase()

   const { lang } = useContext(ShopContext)
   const loc = lang === "ua" ? locales.ua : locales.ru

  //  перемешиваем элементы в массиве случайным образом
  if (products) {
    for (let i = products.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = products[i]
      products[i] = products[j]
      products[j] = temp
    }
  }

  const cards = useGallery()

  return (
    <>
      {products && cards ? (
        <div className="">
          {cards ? (
            <GallerySlider products={cards} card={CardNews} title={loc.title_news} />
          ) : null}
          {products ? (
            <GallerySlider
              products={products}
              card={CardPopular}
              title={loc.title_popular}
            />
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}
