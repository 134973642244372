import AuthContext from "../context/AuthContext"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"


import { useCurrencyRate } from "../swr"
import { useSWRConfig } from "swr"

export default function AdminPanel() {
  const API_URL=process.env.REACT_APP_API_URL
  const {
    user: { isAdmin },
  } = useContext(AuthContext)
  const { mutate } = useSWRConfig()

  const [values, setValues] = useState({
    USD: "",
    EUR: "",
  })
  const [isShow, setIsShow] = useState(false)
  const currencyRate = useCurrencyRate()

  useEffect(() => {
    if (!currencyRate) return

    setValues({
      USD: currencyRate.USD.toString(),
      EUR: currencyRate.EUR.toString(),
    })
  }, [currencyRate])

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    if (isNaN(value)) {
      return
    }
    setValues((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const valuesToSend = { USD: +values.USD, EUR: +values.EUR }

    // send data
    const res = await fetch(`/api/currencyrate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(valuesToSend),
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)      
       
    } else {
      setIsShow(false)
        mutate(`/api/currencyrate`)
    }

  
  }

  const resetErrors = async () => {
    const res = await fetch(`/api/error`, {
      method: "DELETE",
    })
    if (!res.ok) {
      toast.error("Ошибка при очистке ошибок")
    } else {
      toast.success("Файл ошибок очищен")
    }
  }

  const createSitemap = async () => {
    const res = await fetch("/api/sitemap")
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else {
      toast.success("Sitemap успешно обновлен")
    }
  }

  return (
    <div className="w-full hidden lg:block">
      {isAdmin && (
        <div
          className="flex justify-start items-center flex-wrap w-full bg-gray-700
         text-gray-200 text-lg gap-x-5 gap-y-3 py-2 px-2 rounded-md"
        >
          <Link to="/edit_brands">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Бренды
            </p>
          </Link>
          <Link to="/add_catalog">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Новый каталог
            </p>
          </Link>
          <Link to="/add_product">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Новый товар
            </p>
          </Link>
          <Link to="/edit_options">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Опции
            </p>
          </Link>
          <div className="relative">
            <p
              className=" text-gray-200 px-2 py-1 hpver: cursor-pointer
           hover:bg-gray-600"
              onClick={() => setIsShow((prev) => !prev)}
            >
              Курс валют{" "}
            </p>
            <ul
              className={`absolute  top-full left-0 z-10 w-[150px] border border-gray-800 p-1
         rounded-lg bg-gray-100 origin-top transition-all duration-200
          ${isShow ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"} `}
            >
              <li className="group flex text-lg gap-x-2 px-2 py-1 mb-1 text-gray-700  hover:bg-gray-600 ">
                <p className="group-hover:text-gray-200"> USD</p>
                <input
                  className="w-[70px] px-1  text-lg"
                  type="text"
                  name="USD"
                  value={values.USD}
                  onChange={handleChange}
                />
              </li>
              <li className="group flex text-lg gap-x-2 px-2 py-1 mb-1 text-gray-700  hover:bg-gray-600 ">
                <p className="group-hover:text-gray-200">EUR</p>
                <input
                  className="w-[70px] px-1  text-lg"
                  type="text"
                  name="EUR"
                  value={values.EUR}
                  onChange={handleChange}
                />
              </li>
              <button
                onClick={handleSubmit}
                className="w-full text-lg bg-gray-800 px-2 py-1  text-gray-200
                 hover:bg-gray-600 active:scale-[0.98]"
              >
                Сохранить
              </button>
            </ul>
          </div>

          <Link to="/order_admin_list">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Заказы
            </p>
          </Link>
          <Link to="/users_list">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Пользователи
            </p>
          </Link>

          <Link to="/edit_information">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Информация
            </p>
          </Link>
          <Link to="/edit_gallery">
            <p className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
              Галерея
            </p>
          </Link>
          <div className="relative group text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600">
            Ошибки
            <ul className="admin_dropdown_list group-hover:visible group-hover:opacity-100">
              <Link to={`${API_URL}/errors/errors.txt`} target="_about">
                <li
                  className="text-lg  px-2 py-1 mb-1 text-gray-700  hover:bg-gray-600
                 hover:text-gray-200"
                >
                  Открыть
                </li>
              </Link>
              <div>
                <li
                  className="text-lg  px-2 py-1 mb-1 text-gray-700  hover:bg-gray-600
                 hover:text-gray-200"
                  onClick={resetErrors}
                >
                  Очистить
                </li>
              </div>
            </ul>
          </div>
          <div>
            <p
              className="text-gray-200 px-2 py-1 hpver: cursor-pointer hover:bg-gray-600"
              title="Обновить файл sitemap"
              onClick={createSitemap}
            >
              Sitemap
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
