const locales = {
  ru: {
    title_news: "Новинки",
    title_popular: "Популярное",
  },
  ua: {
    title_news: "Новинки",
    title_popular: "Популярне",
  },
}

export default locales
