
export function getCurrencySymbol(currencyShop) {
  switch (currencyShop) {
    case "UAH":
      return "₴"

    case "EUR":
      return "€"

    case "USD":
      return "$"

    default:
      return "₴"
  }
}

export const idToString = (id) => {
  return id === null ? "" : id.toString()
}

export const isNotEmpty = (obj) => {
  return !!Object.keys(obj).length
}

export function getPriceForShow({ currencyShop, currencyRate, product }) {
  const { min, max } = getStringPrice(product.optPrices)
  const showMin = (
    (currencyRate[product.currencyValue] * +min) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const showMax = (
    (currencyRate[product.currencyValue] * +max) /
    currencyRate[currencyShop]
  ).toFixed(2)
  const string = min === max ? showMin : `${showMin} - ${showMax}`
  return string
}

export const getCatTree = (cat, catArray) => {
  var result = [cat.name]
  const findParent = (cat) => {
    const parent = catArray.find((elem) => elem._id === cat.parentId)
    if (parent) {
      result.push(parent.name)
      findParent(parent)
    }
    return
  }
  findParent(cat)

  return result.reverse().join(" ➔  ")
}

export const getShortDescription = (description, length) => {
  return description.length > length
    ? `${description.slice(0, length)}...`
    : description
}

export const stringToPrice = (string) => {
  const priceNum =
    parseFloat(string.replace(/[^\d.,]+/g, "").replace(",", ".")) || 0

  return priceNum === 0 ? "" : priceNum.toFixed(2)
}

export const getQntInCart = (cart) =>
  cart.reduce((acc, item) => acc + +item.qnt, 0)
export const getTotalInCart = (cart) =>
  cart.reduce(
    (acc, item) => ({
      ...acc,
      [item.currencyValue]: acc[item.currencyValue] + item.qnt * item.price,
    }),
    {
      UAH: 0,
      USD: 0,
      EUR: 0,
    }
  )

export const getTotalAmount = (cart) => {
  const totalObj = getTotalInCart(cart)
  let strArr = []
  for (let key in totalObj) {
    if (totalObj[key]) {
      strArr.push(`${totalObj[key].toFixed(2)}${getCurrencySymbol(key)}`)
    }
  }
  return strArr.join(" + ") || "0"
}

export const formatingPhone = (number) => {
  const digit = number.replace(/\D/g, "").slice(2)

  if (digit.length < 4) {
    return `+38 (${digit}`
  } else {
    if (digit.length < 7) {
      return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}`
    } else {
      if (digit.length < 9) {
        return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
          6,
          8
        )}`
      } else {
        if (digit.length >= 9) {
          return `+38 (${digit.slice(0, 3)}) ${digit.slice(3, 6)}-${digit.slice(
            6,
            8
          )}-${digit.slice(8, 10)}`
        }
      }
    }
  }
}

export const formatingDate = (value) => {
  const date = new Date(value)
  const day = date.getDate().toString().padStart(2, "0")
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const year = date.getFullYear().toString().padStart(2, "0")
  return `${day}-${month}-${year}`
}

export const createOptPrices = (values) => {
  let add = { price: "", barcode: "", qnt: "" }
  JSON.parse(JSON.stringify(values.selfOpt))
    .reverse()
    .forEach((option) => {
      const optValues = values.selfOptValues
        .filter((value) => value.optId === option.id)
        .sort((a, b) => (a.ru > b.ru ? 1 : -1))
      add = optValues.map((item) => ({
      ...item,
        add: JSON.parse(JSON.stringify(add)),
      }))
    })

  // копируем заполненные поля из существующего прайс объекта в новый
  const deep = (newOpt, existOpt) => {
    if (Object.hasOwn(newOpt, "price")) {
      // если существующие опции это не объект с полем прайс то копировать нечего
      newOpt.price = existOpt?.price ?? ""
      newOpt.barcode = existOpt?.barcode ?? ""
    } else {
      newOpt.forEach((item) => {
        if (!Array.isArray(existOpt)) return
        // находим такой же объект в массиве существующих опций.
        //  Если такой же есть то копаем глубже, если нет-пропускаем-копировать нечего
        const sameExistOpt = existOpt.find((obj) => obj.id === item.id)
        if (sameExistOpt) {
          deep(item.add, sameExistOpt.add)
        }
      })
    }
    return newOpt
  }

  return deep(add, values.optPrices)
}

export const getStringPrice = (values) => {
  const pricesArray = []
  const deep = (optObj) => {
    if (optObj.hasOwnProperty("price")) {
      if (optObj.price) {
        pricesArray.push(optObj.price)
      }
      return
    } else {
      optObj.forEach((item) => {
        deep(item.add)
      })
    }
  }

  deep(values)

  const min = pricesArray.sort((a, b) => a - b)[0] || ""
  const max = pricesArray.sort((a, b) => b - a)[0] || ""

  let string =
    min === max
      ? `${min.toString()} ${getCurrencySymbol(values.currencyValue)}`
      : `${min}...${max} ${getCurrencySymbol(values.currencyValue)}`

  return { string, min, max }
}

export const localStorageService = {
  get(key) {
    try {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : null
    } catch (error) {
      console.log(`Ошибка получения данных по ключу ${key}`, error)
      return null
    }
  },

  set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(`Ошибка при сохранении по ключу ${key}`, error)
    }
  },
  update(key, newData) {
    try {
      const data = this.get(key) || {}
      this.set(key, { ...data, ...newData })
    } catch (error) {}
  },
  delete(key, id) {
    try {
      const data = this.get(key)
      if (data && data[id]) {
        delete data[id]
        this.set(key, data)
      }
      return data
    } catch (error) {
      console.log(`Ошибка при удалении данных по ключу ${key}`, error)
    }
  },
}

export const ucFirst = (string) => {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const cleanName = (string) => {
  return string
    .replace(/\s+/g, " ")
    .replace(/[^a-zA-Zа-яА-ЯёЁіІїЇєЄ0-9\s.,!?()\-$&]/g, "")
}

export const transformUrl = ({ url, lang }) => {
  if (lang === "") {
    return url.startsWith("/ua") ? url.replace(/^\/ua/, "") || "/" : url
  } else if (lang === "ua") {
    return url.startsWith("/ua") ? url : `/ua${url === "/" ? "" : url}`
  }
  return url
}

export const translateString = async (ru) => {
  const res = await fetch(`/api/translate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ru,
    }),
  })
  const data = await res.json()
  return data.ua
}

export const getLangObj = (lang) => {
  const langKey = lang === "ua" ? "ua" : "ru"
  const langUri = lang === "ua" ? "/ua" : ""
  const langHtml = lang === "ua" ? "uk" : "ru"
  return { langKey, langUri, langHtml }
}


