import React, { useContext } from "react"

import { getCurrencySymbol, getPriceForShow } from "../utils"
import { useCurrencyRate } from "../swr"
import { useNavigate } from "react-router-dom"
import ShopContext from "../context/ShopContext"
import { NOIMAGE } from "../config"

export default function CardPopular({ product, setIsShow, setImage }) {
  const navigate = useNavigate()

  const { currencyShop, langKey, langUri } = useContext(ShopContext)

  const currencyRate = useCurrencyRate()
  return (
    <div
      onClick={() =>
        navigate(`${langUri}/product/${product.slug[langKey]}/${product._id}`)
      }
    >
      <div
        className="flex flex-col items-center gap-1 text-xl/6  p-1 w-[300px] h-[450px]
       border border-gray-500 shadow-md hover:cursor-pointer hover:border-blue-500 group"
      >
        <div className="flex justify-center items-center w-[290px] h-[330px] border border-gray-500 peer">
          <img
            className="max-w-full max-h-full hover:cursor-zoom-in"
            src={
              product.images?.length ? `${product.images[0].md}` : `${NOIMAGE}`
            }
            alt=""
            onClick={
              product.images?.length
                ? (e) => {
                    e.stopPropagation()
                    setIsShow(true)
                    setImage(product.images[0].lg)
                  }
                : (e) => {
                    e.stopPropagation()
                  }
            }
          />
        </div>
        <div
          className="flex-1 text-lg/6 text-gray-800 w-full flex items-center justify-center 
        group-hover:text-red-500 peer-hover:text-gray-800"
        >
          <h3>{product.name[langKey]}</h3>
        </div>
        <div className="w-full h-[35px] flex items-center justify-center bg-gray-200">
          {!!currencyRate && (
            <p>
              {getPriceForShow({
                currencyRate,
                currencyShop,
                product,
              })}{" "}
              {getCurrencySymbol(currencyShop)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
