import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import ShopContext from "../context/ShopContext"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import {
  formatingPhone,  
  getQntInCart,
  getTotalAmount,
  isNotEmpty,
  localStorageService,
} from "../utils/index"
import {  LOGO_NP } from "../config"
import { CARRIERS } from "../config/index"
import { Helmet } from "react-helmet-async"
import locales from "../config/locales/checkout"

export default function Checkout() {
   const API_URL = process.env.REACT_APP_API_URL
  const { cart, setCart, langHtml,langUri, langKey } = useContext(ShopContext)
  

   const loc = locales[langKey]
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const [values, setValues] = useState({
    name: "",
    surname: "",
    phone: "+38 (",
    city: "",
    carrier: "",
    branch: "",
    pickup: true,
    prepaid: true,
  })
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (isNotEmpty(user)) {
      const { name, surname, phone, city, carrier, branch } = user.delivery
      const data = localStorageService.get("checkout")

      const pickup = data && isNotEmpty(data) ? data.pickup : true
      const prepaid = data && isNotEmpty(data) ? data.prepaid : true
      setValues((prev) => ({
        ...prev,
        name,
        surname,
        phone,
        city,
        carrier,
        branch,
        pickup,
        prepaid,
      }))
    } else {
      const data = localStorageService.get("checkout")
      if (data) setValues(JSON.parse(data))
    }
    // установка единственного перевозчика
    setValues((prev) => ({ ...prev, carrier: CARRIERS[0] }))
  }, [user])

  useEffect(() => {
    setDisable(!values.name || !values.phone)
  }, [values])

  useEffect(() => {
    if (values.pickup) {
      setValues((prev) => ({
        ...prev,
        city: "",
        branch: "",
      }))
    }
  }, [values.pickup])

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setValues((prev) => ({ ...prev, [name]: value }))
  }

  const handlePhone = (e) => {
    e.preventDefault()
    const phone = formatingPhone(e.target.value)

    setValues((prev) => ({ ...prev, phone }))
  }

  const handleSendOrder = async () => {
    const totalAmount = getTotalAmount(cart)
    const totalQnt = getQntInCart(cart)
    if (!totalQnt) {
      toast.warning("Заказ пустой, нечего отправлять")
      return
    }

    localStorageService.set("checkout", values)

    const res = await fetch(`/api/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderItems: cart,
        delivery: values,
        totalQnt,
        totalAmount,
        userId: Object.keys(user).length ? user._id : null,
        langKey
      }),
    })
    const data = await res.json()
    if (res.ok) {
      toast.success("Заказ обработан успешно")
      setCart([])
      setDisable(true)
      navigate(`${langUri}/`)
    } else {
      toast.error(data.message)
    }
  }

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <link rel="alternate" hreflang="ru" href={`${API_URL}/checkout`} />
        <link rel="alternate" hreflang="uk" href={`${API_URL}/ua/checkout`} />
        <link rel="canonical" href={`${API_URL}${langUri}/checkout`} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className=" h-full">
        <Links home={true} back={true}>
          <div className="flex items-center justify-end">
            <div className="p-0.5 border-2 border-sb rounded-md">
              <button
                className="text-base text-gray-50   bg-sb shadow-inner rounded-md px-3 py-1
               tracking-wide cursor-pointer disabled:opacity-50 disabled:cursor-auto"
                onClick={handleSendOrder}
                disabled={disable}
              >
                {loc.sendOrder}
              </button>
            </div>
          </div>
        </Links>
        <div className="w-full h-[calc(100%-40px)] flex items-center justify-center">
          <div
            className="w-full  md:w-[70%] lg:w-[40%]  border
              border-gray-500 p-3 bg-[#FDFEFE] shadow-md"
          >
            <div className="py-2 flex flex-col gap-2 justify-center items-stretch text-lg">
              <div>
                <label htmlFor="name">{loc.name}</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="name"
                  name="name"
                  maxLength="30"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="surname">{loc.surname}</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="surname"
                  name="surname"
                  maxLength="30"
                  value={values.surname}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="phone">{loc.phone}</label>
                <input
                  className="block w-full px-2 h-8 my-1"
                  type="text"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div>
                <p> {loc.delivery}</p>
                <div className="flex items-center justify-between py-1">
                  <p>{loc.pickup}</p>
                  <div
                    className="relative rounded-xl w-16 h-6 bg-sb cursor-pointer"
                    onClick={() => {
                      setValues((prev) => ({
                        ...prev,
                        pickup: values.pickup ? false : true,
                      }))
                    }}
                  >
                    <div
                      className={`absolute left-0.5 top-0.5 w-5 h-5 transition-transform duration-300  ${
                        values.pickup ? "" : "translate-x-10"
                      } bg-gray-100 rounded-full`}
                    ></div>
                  </div>
                  <p>{loc.carrier}</p>
                </div>
              </div>

              <div
                className={` transition-all duration-300 ${
                  !values.pickup
                    ? " opacity-100 "
                    : " opacity-30 pointer-events-none "
                }`}
              >
                <div>
                  <div className="flex items-center gap-2  text-xl">
                    <img src={LOGO_NP} className="w-6 h-6" alt="" />
                    <p>{loc.newPost}</p>
                  </div>
                </div>
                <div>
                  <label htmlFor="city">{loc.settlement}</label>
                  <div className="relative">
                    <input
                      className="block w-full px-2 h-8 my-1"
                      type="text"
                      name="city"
                      maxLength="20"
                      value={values.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="branch">{loc.branch}</label>
                  <div className="relative">
                    <input
                      className="block w-full px-2 h-8 my-1"
                      type="text"
                      name="branch"
                      value={values.branch}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <p> {loc.paymentForm}</p>
                  <div className="flex items-center justify-between py-1">
                    <p>{loc.prepaid}</p>
                    <div
                      className="relative rounded-xl w-16 h-6 bg-sb cursor-pointer"
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          prepaid: values.prepaid ? false : true,
                        }))
                      }}
                    >
                      <div
                        className={`absolute left-0.5 top-0.5 w-5 h-5 transition-transform duration-300  ${
                          values.prepaid ? "" : "translate-x-10"
                        } bg-gray-100 rounded-full`}
                      ></div>
                    </div>
                    <p>{loc.postpaid}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
