import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import Links from "../components/Links"
import { useParams } from "react-router-dom"
import { formatingDate, getCurrencySymbol, isNotEmpty } from "../utils/index"
import { useOrderById } from "../swr"
import { Helmet } from "react-helmet-async"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/order"

export default function Order() {
  const { user } = useContext(AuthContext)
 const {  langKey, langHtml } = useContext(ShopContext)
 
  const loc=locales[langKey]
  const { id } = useParams()
 
  const order = useOrderById({ id })
  
   const optionList = order?.orderItems?.reduce((acc, item) => {
         const itemOptions = item.options.map((optObj) => optObj[langKey])
         itemOptions.forEach((option) => {
           if (!acc.includes(option)) acc.push(option)
         })
         return acc
       }, [])??[]

  const isOptions = order
    ? order.orderItems.length !== 0 && "options" in order.orderItems[0]
    : false

 
  
  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className="px-3">
        <Links home={true} back={true} />
        {order && isNotEmpty(user) ? (
          <div className="pt-4 text-lg ">
            <h3 className="font-bold text-center">
              {loc.order} {order.count} {loc.from}{" "}
              {formatingDate(order.createdAt)}
            </h3>
            <div className="flex flex-col sm:flex-row ">
              <div className="flex-1">
                <p>
                  <span className="italic font-bold">{loc.name}</span>{" "}
                  <span>{order.delivery.name}</span>
                </p>
                <p>
                  <span className="italic font-bold">{loc.surname}</span>{" "}
                  <span>{order.delivery.surname}</span>
                </p>
                <p>
                  <span className="italic font-bold">{loc.phone}</span>{" "}
                  <span>{order.delivery.phone}</span>
                </p>
              </div>
              <div className="flex-1">
                <p>
                  <span className="italic font-bold">{loc.city}</span>{" "}
                  <span>{order.delivery.city}</span>
                </p>
                <p>
                  <span className="italic font-bold">{loc.delivery}</span>{" "}
                  <span>
                    {order.delivery.pickup
                      ? "самовывоз"
                      : `перевозчик ${order.delivery.carrier} ${order.delivery.branch}`}
                  </span>
                </p>
                <p>
                  <span className="italic font-bold">{loc.paid}</span>{" "}
                  <span>
                    {order.delivery.prepaid
                      ? "предоплата"
                      : "наложенным платежом"}
                  </span>
                </p>
              </div>
            </div>
            {order.orderItems.length ? (
              <div className="py-3 max-h-[800px] overflow-auto">
                <table className=" border-collapse border border-gray-600 w-full min-w-[768px] table-fixed">
                  <thead>
                    <tr className=" bg-amber-100">
                      <td className="text-center border border-gray-600  p-1">
                        {loc.model}
                      </td>
                      {!!optionList.length&&optionList.map(                      
                            (option, i) => (
                              <td
                                className="text-center border border-gray-600  p-1"
                                key={i}
                              >
                                {option}
                              </td>
                            )
                          )}
                      <td className="text-center border border-gray-600  p-1 w-[100px]">
                        {loc.price}
                      </td>
                      <td className="text-center border border-gray-600  p-1 w-[100px]">
                        {loc.amount}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {order.orderItems.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center border border-gray-600 p-1">
                          {item.name[langKey]}
                        </td>
                        {!!optionList.length && optionList.map((option, j) => (
                              <td
                                className="text-center border border-gray-600 p-1"
                                key={j}
                              >
                             {item.options.find(optObj=>optObj[langKey]===option)?.value[langKey]??''} 
                              </td>
                            ))
                          }
                        <td className="text-center border border-gray-600 p-1">
                          {item.price} {getCurrencySymbol(item.currencyValue)}
                        </td>
                        <td className="text-center border border-gray-600 p-1 ">
                          {item.qnt}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        className=" bg-emerald-50"
                        colSpan={
                          optionList.length+3
                        }
                      >
                        <div className="flex items-center justify-between px-2">
                          <p>
                            {loc.totalQnt} {order.totalQnt}
                          </p>
                          <p>
                            {loc.totalAmount}
                            {order.totalAmount}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}
