import React, { useEffect, useState } from "react"

import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"
import { FaGoogle, FaPaste, FaPlusCircle, FaTimes } from "react-icons/fa"
import { cleanName, translateString } from "../utils"
import { nanoid } from "nanoid"

export default function Options({ values, setValues }) {
  const [optionName, setOptionName] = useState({
    ru: "",
    ua: "",
  })
  const [valueNames, setValueNames] = useState([])

  useEffect(() => {
    setValueNames(values.opt.map((item) => ({ ru: "", ua: "" })))
  }, [values.opt])

  const handleName = async (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues((prev) => ({ ...prev, name: value }))
  }

  const handleOptionName = async (e) => {
    e.preventDefault()
    const {
      value,
      dataset: { lang },
    } = e.target
    const ucValue = value.charAt(0).toUpperCase() + value.slice(1)
    setOptionName((prev) => ({ ...prev, [lang]: ucValue }))
  }

  const handleInputValue = async (e) => {
    e.preventDefault()
    const {
      value,
      dataset: { lang, index },
    } = e.target

    setValueNames((prev) =>
      prev.map((item, i) => (i === +index ? { ...item, [lang]: value } : item))
    )
  }

  const addOption = () => {
    if (valueNames.length === 4) {
      toast.warning("Не рекомендуется делать кол-во опций больше 3")
      return
    }
    if (!optionName.ru.trim() || !optionName.ua.trim()) {
      toast.error("Заполните поле опция")
      return
    }

    const isRepeat = values.opt.find(
      (item) => item.ru === optionName.ru || item.ua === optionName.ua
    )

    if (!isRepeat) {
      setValues((prev) => ({
        ...prev,
        opt: [
          ...prev.opt,
          {
            ru: optionName.ru,
            ua: optionName.ua,
            id: nanoid(),
          },
        ],
      }))
      setOptionName({ ru: "", ua: "" })
    } else {
      toast.error("Такая опция уже существует или зарезервирована")
    }
  }

  const addOptionValue = ({ optIndex, option }) => {
    const { ru, ua } = valueNames[optIndex]

    if (!ru.trim() || !ua.trim()) {
      toast.error("Заполните поле значение опции")
      return
    }
    const value = { ru, ua, optId: option.id, id: nanoid() }
    setValues((prev) => ({ ...prev, optValues: [...prev.optValues, value] }))
    setValueNames((prev) =>
      prev.map((item, i) => (i === optIndex ? { ru: "", ua: "" } : item))
    )
  }

  const deleteOptionsValue = (value) => {
    setValues((prev) => ({
      ...prev,
      optValues: prev.optValues.filter((item) => item.id !== value.id),
    }))
  }

  const deleteOption = (optIndex) => {
    setValues((prev) => {
      //  убираес не только опцию но и связанные с ней Value
      const deletedOptId = prev.opt[optIndex].id
      return {
        ...prev,
        opt: prev.opt.filter((_, i) => i !== optIndex),
        optValues: prev.optValues.filter(
          (value) => value.optId !== deletedOptId
        ),
      }
    })
  }

  const resetOptions = () => {
    setValues({
      _id: null,
      name: "",
      opt: [],
      optValues: [],
    })
  }

  const translateOption = async () => {
    if (!optionName.ru) return
    try {
      const ua = await translateString(optionName.ru)
      setOptionName((prev) => ({ ...prev, ua }))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const translateOptionValue = async (optIndex) => {
    const valueRu = valueNames[optIndex].ru
    if (!valueRu) return
    try {
      const ua = await translateString(valueRu)
      setValueNames((prev) =>
        prev.map((item, i) => (i === optIndex ? { ...item, ua } : item))
      )
    } catch (error) {
      toast.error(error.message)
    }
  }

  const copyNameValue = (optIndex) => {
    const valueRu = valueNames[optIndex].ru
    setValueNames((prev) =>
      prev.map((item, i) => (i === optIndex ? { ...item, ua: valueRu } : item))
    )
  }

  return (
    <div>
      <div className="grid grid-cols-[1fr_1fr_40px] gap-x-3 gap-y-4 items-center">
        <div className="col-span-2">
          <input
            type="text"
            className="my-1 h-8 text-xl px-1 w-full"
            placeholder="Название опций"
            value={values.name}
            onChange={handleName}
            onBlur={(e) =>
              setValues((prev) => ({
                ...prev,
                name: cleanName(e.target.value),
              }))
            }
          />
        </div>

        <FaTimes
          className="text-3xl text-red-500 hover:cursor-pointer"
          title="Новые опции"
          onClick={resetOptions}
        />

        <input
          className="my-2 h-8 text-xl px-1 w-full"
          type="text"
          data-lang="ru"
          placeholder="Название опции (RU)"
          value={optionName.ru}
          onChange={handleOptionName}
          onKeyDown={(e) => (e.key === "Enter" ? addOption() : null)}
          onBlur={(e) =>
            setOptionName((prev) => ({
              ...prev,
              ru: cleanName(e.target.value),
            }))
          }
        />
        <div className="relative">
          <input
            className="my-2 h-8 text-xl px-1 w-full"
            type="text"
            data-lang="ua"
            placeholder="Название опции (UA)"
            value={optionName.ua}
            onChange={handleOptionName}
            onKeyDown={(e) => (e.key === "Enter" ? addOption() : null)}
            onBlur={(e) =>
              setOptionName((prev) => ({
                ...prev,
                ua: cleanName(e.target.value),
              }))
            }
          />
          <div className="absolute top-2 right-1 bg-gray-50 text-base text-gray-700  flex items-center gap-2 px-1 -translate-y-1/2 ">
            <FaGoogle
              className=" hover:cursor-pointer"
              title="Google перевод"
              onClick={translateOption}
            />
          </div>
        </div>

        <FaPlusCircle
          className="text-3xl text-emerald-700 hover:cursor-pointer"
          onClick={addOption}
          title="Добавить опцию"
        />

        {!!values.opt.length &&
          values.opt.map((option, optIndex) => (
            <React.Fragment key={optIndex}>
              <div className="relative group">
                <input
                  className="my-2 h-8 text-xl px-1 w-full"
                  type="text"
                  data-lang="ru"
                  data-index={optIndex}
                  value={valueNames[optIndex]?.ru ?? ""}
                  placeholder={option.ru}
                  onChange={handleInputValue}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? addOptionValue({ optIndex, option })
                      : null
                  }
                  onBlur={(e) =>
                    setValueNames((prev) =>
                      prev.map((item, i) =>
                        i === optIndex
                          ? { ...item, ru: cleanName(e.target.value) }
                          : item
                      )
                    )
                  }
                />
                <FaTimes
                  className="absolute top-1/2 right-1 -translate-y-1/2 text-2xl text-red-500 hover:cursor-pointer"
                  title="Удалить опцию"
                  onClick={() => deleteOption(optIndex)}
                />

                {!!values.optValues.filter((item) => item.optId === option.id)
                  .length && (
                  <div className="absolute hidden group-hover:block z-50 top-full left-0 w-[calc(200%+12px)] pt-1">
                    <div
                      className="flex bg-gray-50 min-w-full max-h-[400px] overflow-auto 
                        border border-gray-700  rounded-md p-3 shadow-md flex-wrap gap-3 "
                    >
                      {values.optValues
                        .filter((item) => item.optId === option.id)
                        .sort((a, b) => (a.ru > b.ru ? 1 : -1))
                        .map((value, valueIndex) => (
                          <div
                            key={valueIndex}
                            className="flex items-center justify-center gap-4 border border-green-800
                                 rounded-md  px-2 py-1 text-xl"
                          >
                            <div>{value.ru}</div>
                            <FaTimes
                              className="text-xl text-red-500 hover:cursor-pointer"
                              title="Удалить значение опции"
                              onClick={() => deleteOptionsValue(value)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="relative group">
                <input
                  className="my-2 h-8 text-xl px-1 w-full"
                  type="text"
                  data-lang="ua"
                  data-index={optIndex}
                  value={valueNames[optIndex]?.ua ?? ""}
                  placeholder={option.ua}
                  onChange={handleInputValue}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? addOptionValue({ optIndex, option })
                      : null
                  }
                  onBlur={(e) =>
                    setValueNames((prev) =>
                      prev.map((item, i) =>
                        i === optIndex
                          ? { ...item, ua: cleanName(e.target.value) }
                          : item
                      )
                    )
                  }
                />

                <div
                  className="absolute top-2 right-1 bg-gray-50 text-base text-gray-700 
                 flex items-center gap-2 px-1 -translate-y-1/2 "
                >
                  <FaGoogle
                    className="hover:cursor-pointer"
                    title="Google перевод"
                    onClick={() => translateOptionValue(optIndex)}
                  />
                  <FaPaste
                    className=" hover:cursor-pointer"
                    title="Скопировать"
                    onClick={() => copyNameValue(optIndex)}
                  />
                </div>
                {!!values.optValues.filter((item) => item.optId === option.id)
                  .length && (
                  <div className="absolute hidden group-hover:block z-50 top-full right-0 w-[calc(200%+12px)] pt-1">
                    <div
                      className="flex bg-gray-50 min-w-full max-h-[400px] overflow-auto 
                        border border-gray-700  rounded-md p-3 shadow-md flex-wrap gap-3 "
                    >
                      {values.optValues
                        .filter((item) => item.optId === option.id)
                        .sort((a, b) => (a.ua > b.ua ? 1 : -1))
                        .map((value, valueIndex) => (
                          <div
                            key={valueIndex}
                            className="flex items-center justify-center gap-4 border border-green-800
                                 rounded-md  px-2 py-1 text-xl"
                          >
                            <div>{value.ua}</div>
                            <FaTimes
                              className="text-xl text-red-500 hover:cursor-pointer"
                              title="Удалить значение опции"
                              onClick={() => deleteOptionsValue(value)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <FaPlusCircle
                className="text-3xl text-emerald-700 hover:cursor-pointer"
                onClick={() => addOptionValue({ optIndex, option })}
                title="Добавить значение опции"
              />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}
