import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Navbar from "./Navbar"
import { useNavigation } from "react-router-dom"
import Spinner from "./Spinner"
import { ToastContainer } from "react-toastify"

export default function Layout({ children }) {
  const navigation = useNavigation()
  const isLoading = navigation.state === "loading"

  return (
    <>
      <div
        className="font-Robotto min-h-[100vh] grid grid-cols-1 
    grid-rows-[150px_minmax(0,1fr)_100px] gap-y-3 "
      >
        <header>
          <Header />
        </header>
        <div
          className="grid grid-cols-1 lg:grid-cols-[320px_minmax(0,1fr)] gap-3 h-full justify-self-center
     grid-rows-[min-content,1fr] lg:grid-rows-none w-full max-w-[1800px] "
        >
          <aside>
            <Navbar />
          </aside>

          <main className="border border-gray-300 rounded-md p-2">
            {isLoading ? <Spinner /> : children}
          </main>
        </div>

        <footer>
          <Footer />
        </footer>
      </div>
      <ToastContainer />
    </>
  )
}
