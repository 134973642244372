import React from "react"
import AdminPriceBlock from "./AdminPriceBlock"

export default function AdminOptionsPrice({
  optPrices,
  optPricesOrigin,
  level,
  saveValues,
  crumbs,
}) {
  if (!level && Object.hasOwn(optPrices,'price')) {
    return (
      <div className="flex  justify-end py-1">
        <AdminPriceBlock
          crumbs={crumbs}
          optPrices={optPrices}
          saveValues={saveValues}
        />
      </div>
    )
  } else {
    return (
      <div>
        {optPrices.map((optObj) => (
          <div key={`${level}${optObj.ru}${crumbs.join("")}`}>  
            {optObj.add.hasOwnProperty("price") ? (
              <div className="flex justify-between items-center option pr-2 py-1 overflow-x-auto">
                <div
                  style={{ paddingLeft: (level+1) * 20 + "px" }}
                 
                >
                  {optObj.ru}
                </div>
                
                <AdminPriceBlock
                   optPricesOrigin={optPricesOrigin}
                  crumbs={[...crumbs,optObj.ru]}
                  optPrices={optObj.add}
                  closeArrPrices={optPrices}
                  saveValues={saveValues}
                />
              </div>
            ) : (
              <>
                <div
                  style={{ paddingLeft: (level+1) * 20 + "px" }}
                  className=" option py-1 flex items-center"
                >
                  {optObj.ru}
                </div>

                  <AdminOptionsPrice
                    optPricesOrigin={optPricesOrigin}
                  level={level + 1}
                  crumbs={[...crumbs, optObj.ru]}
                  optPrices={optObj.add}
                  saveValues={saveValues}
                />
              </>
            )}
          </div>
        ))}
      </div>
    )
  }
}
