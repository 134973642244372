const locales = {
  ru: {
    title: "Авторизация",
    content: "Авторизация в магазине Кармен",
    auth: "Авторизация",
    cond: "Для авторизации необходимо наличие аккаунта в Viber или Telegram",
  },
  ua: {
    title: "Авторизація",
    content: "Авторизація в магазині Кармен",
    auth: "Авторизація",
    cond: "Для авторизації необхідна наявність акаунта у Viber або Telegram",
  },
}

export default locales
