const locales = {
  ru: {
    information: "Информация",
    contacts: "Контакты",
    map: "Карта",
  },
  ua: {
    information: "Інформація",
    contacts: "Контакти",
    map: "Мапа",
  },
}

export default locales
