import AccessDenied from "../components/AccessDenied"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useInformation } from "../swr"
import { FaSave } from "react-icons/fa"
import { Helmet } from "react-helmet-async"
import { isNotEmpty } from "../utils"

export default function EditInformation() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const navigate = useNavigate()

  const information = useInformation()
  const [values, setValues] = useState({})

  useEffect(() => {
    if (!information) return
    setValues({ ...information })
  }, [information])

  const changeHandler = (e) => {
    e.preventDefault()
    const {
      name,
      value,
      dataset: { lang },
    } = e.target
    setValues((prev) => ({ ...prev, [name]: { ...prev[name], [lang]: value } }))
  }

  const submitHandler = async () => {
    const res = await fetch(`/api/information`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
    const data = res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    navigate("/")
  }

  return (
    <>
      <Helmet>
        <title>Редактировать страницу информации</title>
        <meta
          name="description"
          content="Редактирование страницы информации в машазине Кармен"
        />
      </Helmet>

      {isAdmin ? (
        <div className="px-2">
          <Links home={true} back={true}>
            <div className="flex justify-end items-center">
              <div
                className="text-3xl text-sb cursor-pointer hover:text-sb-dark"
                onClick={submitHandler}
              >
                <FaSave />
              </div>
            </div>
          </Links>
          {isNotEmpty(values) && (
            <div className="pt-4 grid grid-cols-2 gap-x-2 gap-y-1">
              <label className="block text-xl " htmlFor="aboutUs">
                О нас
              </label>
              <label className="block text-xl " htmlFor="aboutUsUa">
                Про нас
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="aboutUs"
                name="aboutUs"
                data-lang="ru"
                onChange={changeHandler}
                value={values.aboutUs.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="aboutUsUa"
                name="aboutUs"
                data-lang="ua"
                onChange={changeHandler}
                value={values.aboutUs.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="conditions">
                Условия сотрудничества
              </label>
              <label className="block text-xl" htmlFor="conditionsUa">
                Умови співробітництва
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="conditions"
                name="conditions"
                data-lang="ru"
                onChange={changeHandler}
                value={values.conditions.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="conditionsUa"
                name="conditions"
                data-lang="ua"
                onChange={changeHandler}
                value={values.conditions.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="productReturn">
                Возврат товара
              </label>
              <label className="block text-xl" htmlFor="productReturnUa">
                Повернення товару
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="productReturn"
                name="productReturn"
                data-lang="ru"
                onChange={changeHandler}
                value={values.productReturn.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="productReturnUa"
                name="productReturn"
                data-lang="ua"
                onChange={changeHandler}
                value={values.productReturn.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="delivery">
                Доставка
              </label>
              <label className="block text-xl" htmlFor="deliveryUa">
                Доставка
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="delivery"
                name="delivery"
                data-lang="ru"
                onChange={changeHandler}
                value={values.delivery.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="deliveryUa"
                name="delivery"
                data-lang="ua"
                onChange={changeHandler}
                value={values.delivery.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="address">
                Адрес
              </label>
              <label className="block text-xl" htmlFor="addressUa">
                Адреса
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="address"
                name="address"
                data-lang="ru"
                onChange={changeHandler}
                value={values.address.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="addressUa"
                name="address"
                data-lang="ua"
                onChange={changeHandler}
                value={values.address.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="workingHours">
                Часы работы
              </label>
              <label className="block text-xl" htmlFor="workingHoursUa">
                Час роботи
              </label>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="workingHours"
                name="workingHours"
                data-lang="ru"
                onChange={changeHandler}
                value={values.workingHours.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px] text-lg indent-6"
                id="workingHours"
                name="workingHours"
                data-lang="ua"
                onChange={changeHandler}
                value={values.workingHours.ua}
              ></textarea>
              <label className="block text-xl" htmlFor="payment">
                Цены и оплата
              </label>
              <label className="block text-xl" htmlFor="paymentUa">
                Ціни та оплата
              </label>
              <textarea
                className="p-1 w-full min-h-[100px]  text-lg indent-6"
                id="payment"
                name="payment"
                data-lang="ru"
                onChange={changeHandler}
                value={values.payment.ru}
              ></textarea>
              <textarea
                className="p-1 w-full min-h-[100px]  text-lg indent-6"
                id="paymentUa"
                name="payment"
                data-lang="ua"
                onChange={changeHandler}
                value={values.payment.ua}
              ></textarea>
            </div>
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}
