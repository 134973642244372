import {  toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { io } from "socket.io-client"
import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AuthContext from "../context/AuthContext"
import { Helmet } from "react-helmet-async"
import { FaTelegram, FaTimes, FaViber } from "react-icons/fa"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/login"
import { Buffer } from 'buffer'
import {nanoid} from 'nanoid'


export default function Login() {
  
  const API_URL = process.env.REACT_APP_API_URL
  const VIBER_BOT_URI = process.env.REACT_APP_VIBER_BOT_URI
  const TELEGRAM_BOT_URI = process.env.REACT_APP_TELEGRAM_BOT_URI

  const [authKey] = useState(nanoid(8))
  const { langHtml,langKey,langUri } = useContext(ShopContext) 

  const loc = locales[langKey]
  const contextUri = Buffer.from(JSON.stringify({ authKey, langKey })).toString('base64') 
  const { setUser } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const login = async () => {
      const res = await fetch(`/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ authKey }),
      })
      const data = await res.json()

      if (!res.ok) {
        toast.error(data.message)
        return
      }

      setUser(data.user)
      navigate(-1)
    }
    const socket = io(API_URL, { transports: ["websocket"] })
    socket.on("authkey", (arg) => {      
      if (arg === authKey) {
        login()
      }
    })
    return () => {
      socket.disconnect()
    }
  }, [authKey])

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <link rel="alternate" hreflang="ru" href={`${API_URL}/login`} />
        <link rel="alternate" hreflang="uk" href={`${API_URL}/ua/login`} />
        <link rel="canonical" href={`${API_URL}${langUri}/login`} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className="flex justify-center items-center w-full h-full">
        <div
          className="relative px-6 py-4 max-w-full w-[400px] border
         border-gray-500 bg-gray-50 shadow-md shadow-gray-400/50"
        >
          <div
            className="absolute text-3xl top-3 right-3 text-red-500 cursor-pointer"
            onClick={() => navigate(-1)}
            title="Закрыть"
          >
            <FaTimes />
          </div>
          <h1 className="font-bold text-center">{loc.auth}</h1>
          <div className="py-5 flex justify-between items-center text-7xl">
            <Link
              to={`viber://pa?chatURI=${VIBER_BOT_URI}&context=${contextUri}`}
              target="_blank"
            >
              <FaViber
                className="text-[#7360f2] cursor-pointer active:scale-[0.98]"
                title="Авторизация через Viber"
                name="viber"
              />
            </Link>
            <Link
              to={`https://t.me/${TELEGRAM_BOT_URI}?start=${contextUri}`}
              target="_blank"
            >
              <FaTelegram
                className="text-[#2aabee] cursor-pointer active:scale-95"
                title="Авторизация через Telegram"
                name="telegram"
              />
            </Link>
          </div>

          <div className="flex justify-center items-center">
            <p className="text-center text-base bg-amber-100 p-2  shadow-md shadow-gray-700/50">
              {loc.cond}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
