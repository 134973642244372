const locales = {
  ru: {
    title: "Заказы клиента<",
    content: "Заказы клиента магазина Кармен",
    date: "Дата",
    totalAmount: "Общая сумма",
  },
  ua: {
    title: "Замовлення клієнта",
    content: "Замовлення клієнта магазину Кармен",
    date: "Дата",
    totalAmount: "Загальна сума"
  },
}

export default locales
