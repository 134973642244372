import { Outlet, useNavigate } from "react-router-dom"
import Layout from "./components/Layout"
import { SWRConfig } from "swr"
import { useContext } from "react"
import ShopContext from "./context/ShopContext"


function App() {
  const navigate = useNavigate()
  const {  langUri } = useContext(ShopContext)
  

  return (
    <SWRConfig
      value={{
        onError: (error) => navigate(`${langUri}/error_server?message=${error.message}`),
      }}
    >     
      <Layout>
        <Outlet />
      </Layout>
    </SWRConfig>
  )
}

export default App
