import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom"
import Home from "./pages/Home"
import { ShopProvider } from "./context/ShopContext"
import { AuthProvider } from "./context/AuthContext"
import Catalog from "./pages/Catalog"
import Map from "./pages/Map"
import Product from "./pages/Product"
import Login from "./pages/Login"
import AddCatalog from "./pages/AddCatalog"
import AddProduct from "./pages/AddProduct"
import EditInformation from "./pages/EditInformation"
import UsersList from "./pages/UsersList"
import OrderAdminList from "./pages/OrderAdminList"
import Order from "./pages/Order"
import Cart from "./pages/Cart"
import Checkout from "./pages/Checkout"
import OrderUserList from "./pages/OrderUserList"
import UserProfile from "./pages/UserProfile"
import EditGallery from "./pages/EditGallery"
import EditOptions from "./pages/EditOptions"
import EditProduct from "./pages/EditProduct"
import EditCatalog from "./pages/EditCatalog"
import ErrorRouter from "./pages/ErrorRouter"
import ErrorServer from "./pages/ErrorServer"
import Information from "./pages/Information"
import Contacts from "./pages/Contacts"
import EditBrands from "./pages/EditBrands"
import { BrandProvider } from "./context/BrandContext"
import { HelmetProvider } from "react-helmet-async"
import { getLangObj } from "./utils"



const fetchCatalog = async (id) => {
  const res = await fetch(`/api/catalogs/catalog/${id}`)
  const data = await res.json()
  if (!res.ok) {
    throw new Error(data.message)
  }
  if (!data.catalog) {
    throw new Error("Каталог не найден")
  }
  return data.catalog
}

const fetchProduct = async (id) => {
  const res = await fetch(`/api/products/product/${id}`)
  const data = await res.json()
  if (!res.ok) {
    throw new Error(data.message)
  }
  if (!data.product) {
    throw new Error("Товар не найден")
  }
  return data.product
}
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorRouter />,
      children: [
        {
          path: "/:lang?",
          element: <Home />,
        },
        // временное решение для старых ссылок
        {
          path: "/catalog/:id",
          loader: async ({ params }) => {
            const { id } = params
            const catalog = await fetchCatalog(id)

            return redirect(`/catalog/${catalog.slug.ru}/${id}`)
          },
        },
        {
          path: "/product/:id",
          loader: async ({ params }) => {
            const { id } = params
            const product = await fetchProduct(id)
            return redirect(`/product/${product.slug.ru}/${id}`)
          },
        },

        // -------------------------------------------

        {
          path: "/:lang?/catalog/:slug/:id",
          element: <Catalog />,
          loader: async ({ params }) => {
            const { slug, id, lang } = params
            const { langKey,langUri }=getLangObj(lang)
            const catalog = await fetchCatalog(id)
            if (slug !== catalog.slug[langKey]) {           
              return redirect(`${langUri}/catalog/${catalog.slug[langKey]}/${id}`)
            }
            return catalog
          },
        },

        {
          path: "/:lang?/product/:slug/:id",
          element: <Product />,
          loader: async ({ params }) => {
            const { id, slug, lang } = params
             const { langKey, langUri } = getLangObj(lang)
            const product = await fetchProduct(id)
            if (slug !== product.slug[langKey]) {
              return redirect(`${langUri}/product/${product.slug[langKey]}/${id}`)
            }
           
            return product
          },
        },
       

        {
          path: "/:lang?/cart",
          element: <Cart />,
        },
        {
          path: "/:lang?/checkout",
          element: <Checkout />,
        },
        {
          path: "/:lang?/information",
          element: <Information />,
        },
        {
          path: "/:lang?/contacts",
          element: <Contacts />,
        },
        {
          path: "/:lang?/map",
          element: <Map />,
        },

        {
          path: "/:lang?/login",
          element: <Login />,
        },

        {
          path: "/add_catalog",
          element: <AddCatalog />,
        },

        {
          path: "/edit_catalog/:id",
          element: <EditCatalog />,
          loader: async ({ params }) => {
            const { id } = params
            const catalog = await fetchCatalog(id)
            return catalog
          },
        },
        {
          path: "/add_product",
          element: <AddProduct />,
        },
        {
          path: "/edit_product/:id",
          element: <EditProduct />,
          loader: async ({ params }) => {
            const { id } = params
            const product = await fetchProduct(id)
            return product
          },
        },
        {
          path: "/edit_options",
          element: <EditOptions />,
        },
        {
          path: "/edit_information",
          element: <EditInformation />,
        },

        {
          path: "/users_list",
          element: <UsersList />,
        },
        {
          path: "/order_admin_list",
          element: <OrderAdminList />,
        },
        {
          path: "/:lang?/order_page/:id",
          element: <Order />,
        },

        {
          path: "/:lang?/order_user_list/:id",
          element: <OrderUserList />,
        },
        {
          path: "/:lang?/user_profile",
          element: <UserProfile />,
        },
        {
          path: "/edit_gallery",
          element: <EditGallery />,
        },
        {
          path: "/:lang?/error_server",
          element: <ErrorServer />,
        },
        {
          path: "/edit_brands",
          element: <EditBrands />,
        },
      ],
    },
  ] 
)

const langURL = window.location.pathname.startsWith("/ua") ? "ua" : ""

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <ShopProvider langURL={ langURL}>
          <BrandProvider>
            <RouterProvider router={router} />
          </BrandProvider>
        </ShopProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
)
