const locales = {
  ru: {
    title: "Профиль клиента",
    content: "Профиль клиента магазина Кармен",
    authMethod: "Метод автормзации ",
    userPhone: "Телефон",
    delivery: "Доставка:",
    name: "Имя",
    surName: "Фамилия",
    phone: "Телефон",
    city: "Город",
    carrier: "Перевозчик",
    branch: "Отделение",
    save: "Сохранить",
  },
  ua: {
    title: "Профіль клієнта",
    content: "Профіль клієнта магазину Кармен",
    authMethod: "Метод авторизації",
    userPhone: "Телефон",
    delivery: "Доставка:",
    name: "Ім'я",
    surName: "Прізвище",
    phone: "Телефон",
    city: "Місто",
    carrier: "Перевізник",
    branch: "Відділення",
    save: "Зберегти",
  },
}

export default locales
