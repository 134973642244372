import React, { useContext, useState } from "react"
import { NOIMAGE } from "../config"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AccessDenied from "../components/AccessDenied"
import ModalImage from "../components/ModalImage"
import {
  FaCircle,
  FaGoogle,
  FaImage,
  FaPaste,
  FaSave,
  FaTimes,
  FaWindowClose,
} from "react-icons/fa"
import ModalDialog from "../components/ModalDialog"
import { useSWRConfig } from "swr"
import { useCatalogs } from "../swr"
import { useNavigate } from "react-router-dom"
import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"
import BrandContext from "../context/BrandContext"
import { cleanName, idToString, translateString } from "../utils"

export default function AddCatalog() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)
  const { brands } = useContext(BrandContext)
  const { mutate } = useSWRConfig()

  const navigate = useNavigate()

  const [values, setValues] = useState({
    name: { ru: "", ua: "" },
    parent: "",
    parentId: null,
    image: { md: "", sm: "" },
  })
  const [showImageUpload, setShowImageUpload] = useState(false)

  const catalogs = useCatalogs()

  const listForMenu = catalogs
    ? catalogs.sort((a, b) => (a.tree.ru > b.tree.ru ? 1 : -1))
    : []
  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    setValues((prev) => ({ ...prev, name: { ...prev.name, [name]: value } }))
  }

  const handleChangeParent = (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues((prev) => ({ ...prev, parent: value }))
  }

  const handleListClick = (item) => {
    if (item.haveProducts) {
      toast.warning("В этом каталоге есть товары, выберите другой каталог")
      return
    }
    setValues((prev) => ({ ...prev, parent: item.name.ru, parentId: item._id }))
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
      const { md, sm } = await saveImage({ file })
      setValues((prev) => ({ ...prev, image: { md, sm } }))
      setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
  }
  const deleteImage = () => {
    setValues((prev) => ({ ...prev, image: { md: "", sm: "" } }))
  }

  const handleTranslate = async () => {
    if (!values.name.ru) return

    try {
      const ua = await translateString(values.name.ru)
      setValues((prev) => ({ ...prev, name: { ...prev.name, ua } }))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const copyName = () => {
    setValues((prev) => ({ ...prev, name: { ...prev.name, ua: prev.name.ru } }))
  }

  const handleBlur = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      name: { ...prev.name, [name]: cleanName(value) },
    }))
  }

  const handleSave = async () => {
    // Проверка на заполнение поля имени категории

    if (!values.name.ru || !values.name.ua) {
      toast.error("Поле Каталог должно быть заполнено на обоих языках")
      return
    }
    const res = await fetch(`/api/catalogs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
    } else {
      const brandIds = brands.map((item) => item._id)
      mutate({ url: `/api/catalogs/navbar`, swrData: { brandIds } })
      navigate("/")
    }
  }

  return (
    <>
      <Helmet>
        <html lang="ru" />
        <title>Добавить каталог</title>
        <meta
          name="description"
          content="Добавление каталога в машазин Кармен"
        />
      </Helmet>
      {!isAdmin ? (
        <AccessDenied />
      ) : (
        <div className="px-2 text-lg">
          <div className="block">
            <Links home={true} back={true}>
              <div className="flex justify-end items-center py-1 text-3xl">
                <FaSave
                  className="text-sb  hover:cursor-pointer hover:text-sb-dark"
                  title="Сохранить"
                  onClick={handleSave}
                />
              </div>
            </Links>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 pt-4">
              <div className="justify-self-center w-[300px]">
                <div
                  className="w-[300px] h-[400px] flex justify-center items-center
                 border border-gray-700 p-1"
                >
                  <img
                    className="max-w-full max-h-full"
                    src={values.image.md ? `${values.image.md}` : NOIMAGE}
                    alt=""
                  />
                </div>

                <div className="flex text-3xl justify-around w-full my-2">
                  <FaImage
                    className="flex cursor-pointer  text-sb  "
                    onClick={() => {
                      setShowImageUpload(true)
                    }}
                    name="save"
                    title="Сохранить"
                  />
                  <FaWindowClose
                    className="flex cursor-pointer text-red-500"
                    onClick={deleteImage}
                    name="delete"
                    title="Удалить"
                  />
                </div>
              </div>
              <div>
                <input
                  className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                  type="text"
                  name="ru"
                  value={values.name.ru}
                  onChange={handleChange}
                  placeholder="Каталог (RU)"
                  onBlur={handleBlur}
                />
                <div className="relative">
                  <input
                    className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                    type="text"
                    name="ua"
                    value={values.name.ua}
                    onChange={handleChange}
                    placeholder="Каталог (UA)"
                    onBlur={handleBlur}
                  />
                  <div
                    className="absolute top-0 right-1 bg-gray-50 text-base text-gray-700 
                  flex items-center gap-2 px-1 -translate-y-1/2 "
                  >
                    <FaGoogle
                      className=" hover:cursor-pointer"
                      title="Google перевод"
                      onClick={handleTranslate}
                    />
                    <FaPaste
                      className=" hover:cursor-pointer"
                      title="Скопировать"
                      onClick={copyName}
                    />
                  </div>
                </div>

                <div className="relative group">
                  <input
                    className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                    type="text"
                    value={values.parent}
                    onChange={handleChangeParent}
                    placeholder="Родительский каталог"
                    readOnly
                    autoComplete="off"
                  />
                  <FaTimes
                    className="block -translate-x-1/2 -translate-y-1/2 text-5
                       absolute text-red-500 top-1/2 right-0 hover:cursor-pointer"
                    onClick={() =>
                      setValues((prev) => ({
                        ...prev,
                        parent: "",
                        parentId: null,
                      }))
                    }
                  />
                  <ul className="droplist_wrapper group-hover:block">
                    {listForMenu.length && (
                      <div className=" droplist">
                        {listForMenu.map((item, i) => (
                          <li
                            className={`px-2 flex items-center gap-2 cursor-pointer hover:bg-gray-200 
                               ${
                                 idToString(item._id) ===
                                 idToString(values.parentId)
                                   ? "bg-gray-200"
                                   : ""
                               }
                              ${item.haveProducts && "pointer-events-none"}`}
                            key={i}
                            onClick={() => handleListClick(item)}
                          >
                            <p>{item.tree.ru}</p>
                            {item.haveProducts && (
                              <FaCircle className="text-xs text-red-500" />
                            )}
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showImageUpload ? (
        <ModalDialog>
          <ModalImage
            handleUploadChange={handleUploadChange}
            setShowImageUpload={setShowImageUpload}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
