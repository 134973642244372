const locales = {
  ru: {
    title: "Кармен-оптовый магазин",
    content:
      "Продаем оптом и мелким оптом, в розницу белье мужское, белье женское, женские брюки, велосипедки,гетры, гольфы,заколенки,кальсоны,капри, колготки, колготки теплые, колготки с рисунком, колготки детские, колготки женские, лосины,носки мужские, носки детские, носки женские, чулки",
  },
  ua: {
    title: "Кармен-оптовий магазин",
    content:
      "Продаємо оптом і дрібним оптом, вроздріб білизну чоловічу, білизну жіночу, жіночі штани, велосипедки, гетри, гольфи, заколенки, кальсони, капрі, колготки, колготки теплі, колготки з малюнком, колготки дитячі, колготки жіночі, лосини,шкарпетки чоловічі, шкарпетки дитячі, шкарпетки жіночі, панчохи",
  },
}

export default locales
