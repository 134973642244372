import React, { useContext }  from "react"
import Links from "../components/Links"
import { useInformation } from "../swr"
import {  CONTACTS_IMAGE, PHONE1, PHONE2, PHONE3 } from "../config"
import { Helmet } from "react-helmet-async"
import MapFrame from "../components/MapFrame"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/contacts"

export default function Contacts() {
   const API_URL = process.env.REACT_APP_API_URL
  const information = useInformation()

  const { langKey,langHtml,langUri } = useContext(ShopContext) 
 const loc = locales[langKey]

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href={`${API_URL}/contacts`}
        />
        <link
          rel="alternate"
          hreflang="uk"
          href={`${API_URL}/ua/contacts`}
        />
        <link
          rel="canonical"
          href={`${API_URL}${langUri}/contacts`}
        />
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className="w-full h-full flex flex-col gap-2 px-1">
        <Links home={true} back={true}></Links>
        <div className="px-2 basis-5/12 border border-gray-500 rounded  text-xl grid grid-cols-1 xl:grid-cols-[minmax(0,1fr)_1fr] gap-5 justify-items-center items-center">
          <div className=" border border-gray-600 p-1 flex items-center justify-center">
            <img
              className="max-w-full max-h-full"
              src={CONTACTS_IMAGE}
              alt={ loc.shop_name}
            />
          </div>
          <div className="overflow-hidden justify-self-start ">
            <h2 className="font-bold ">{loc.address}</h2>
            <p>{information?.address?.[langKey] ?? null}</p>
            <h2 className="font-bold ">{loc.phones}</h2>
            <p>{PHONE1}</p>
            <p>{PHONE2}</p>
            <p>{PHONE3}</p>
            <h2 className="font-bold">{loc.hours}</h2>
            <pre className="text-xl">
              {information?.workingHours?.[langKey] ?? null}
            </pre>
          </div>
        </div>
        <div className=" flex-1 min-h-[500px] border border-gray-500 rounded">
          <MapFrame />
        </div>
      </div>
    </>
  )
}
