import React from "react"
import { getCurrencySymbol, stringToPrice } from "../utils"
import { FaMinus, FaPlus } from "react-icons/fa"

export default function ClientPriceBlock({
  optPrices,
  saveValues,
  product,
}) {
  const price = optPrices.price
    ? optPrices.price + " " + getCurrencySymbol(product.currencyValue)
    : ""

  const changeHandler = (e) => {
    e.preventDefault()
    const { value } = e.target
    optPrices.qnt = value.replace(/[^\d]+/g, "")
    saveValues()
  }

  const inc = () => {
    let value = +optPrices.qnt
    value = value >= 999 ? value.toString() : (value + 1).toString()
    optPrices.qnt = value
    saveValues()
  }

  const dec = () => {
    let value = +optPrices.qnt
    value = value <= 1 ? "" : (value - 1).toString()
    optPrices.qnt = value
    saveValues()
  }

  return (
    <div className="px-1 gap-x-3  flex items-center justify-end">
      <div className="flex items-center gap-x-1 ">
        <FaMinus
          className="text-red-500 text-base cursor-pointer"
          onClick={dec}
        />
        <input
          className="max-w-10 text-center text-base tracking-widest"
          type="text"
          value={optPrices.qnt}
          onChange={changeHandler}
          maxLength={3}
        />

        <FaPlus
          className="text-green-700 text-base cursor-pointer"
          onClick={inc}
        />
      </div>
      <div className="flex justify-end min-w-[65px] select-none">{price}</div>
    </div>
  )
}
