import React, { useContext, useState } from "react"
import {
  FaBars,
  FaBinoculars,
  FaHome,
  FaMapMarkerAlt,
  FaTimes,
  FaUser,
} from "react-icons/fa"
import { FaArrowRightToBracket } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import {  isNotEmpty } from "../../utils"
import locales from "../../config/locales/heder"
import ShopContext from "../../context/ShopContext"

export default function NavMenu() {
  const { setUser, user } = useContext(AuthContext)
  const {  langUri, langKey } = useContext(ShopContext)
  const loc =  locales[langKey]
 
  const navigate = useNavigate()

  const [isShowMainMenu, setIsShowMainMenu] = useState(false)
  const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false)
  

  const isUser = isNotEmpty(user)

  const toggleMainUserMenu = () => {
    setIsShowMainMenu((prev) => !prev)
  }

  const toggleBurgerMenu = () => {
    setIsShowBurgerMenu((prev) => !prev)
  }

  const logout =async () => {
    const res = await fetch('/api/user/logout')
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
      return
    }
    setUser({})
  }

  return (
    <>
      <nav>
        <ul className="hidden md:flex md:items-center  list-none gap-x-6 text-2xl text-sb">
          <li
            className="hover:text-sb-dark cursor-pointer"
            title={loc.title_main}
          >
            <Link to={`${langUri}/`}>
              <FaHome className="text-3xl" />
            </Link>
          </li>
          <li
            className="hover:text-sb-dark cursor-pointer"
            title={loc.title_contacts}
          >
            <Link to={`${langUri}/contacts`}>
              <FaBinoculars />
            </Link>
          </li>
          <li
            className="hover:text-sb-dark cursor-pointer"
            title={loc.title_map}
          >
            <Link to={`${langUri}/map`}>
              <div className="flex items-center gap-x-1">
                <FaMapMarkerAlt />
                {/* <span>Карта</span> */}
              </div>
            </Link>
          </li>
          <li className="relative hover:text-sb-dark cursor-pointer">
            {isUser ? (
              <>
                <FaUser onClick={toggleMainUserMenu} />

                <ul
                  className={`${
                    isShowMainMenu
                      ? "opacity-100 scale-y-100"
                      : "opacity-0 scale-y-0"
                  } absolute z-50 overflow-hidden top-[150%] text-lg text-gray-900 origin-top transition-all duration-200
                   -right-[10px] w-[250px] bg-[#fbfcfc] border border-[#888] list-none rounded-md shadow-md shadow-black/30 `}
                >
                  <li
                    className="py-2 px-5 w-full cursor-auto flex justify-start items-center gap-3 hover:bg-[#ecf0f1]
                  border-sky-300 border-b-2"
                  >
                    <>
                      <FaUser className="text-sb" />
                      <p>{user.userName}</p>
                      <div
                        className="cursor-pointer text-xl text-red-500 ml-auto"
                        onClick={toggleMainUserMenu}
                      >
                        <FaTimes />
                      </div>
                    </>
                  </li>
                  <li className="py-2 px-5 w-full hover:bg-[#ecf0f1] ">
                    <Link to={`${langUri}/order_user_list/${user._id}`}>
                      <p onClick={toggleMainUserMenu}>{loc.my_orders}</p>
                    </Link>
                  </li>
                  <li className="py-2 px-5 w-full hover:bg-[#ecf0f1]">
                    <Link to={`${langUri}/user_profile`}>
                      <p onClick={toggleMainUserMenu}>{loc.profile}</p>
                    </Link>
                  </li>
                  <li
                    className="py-2 px-5 w-full hover:bg-[#ecf0f1]"
                    onClick={() => logout()}
                  >
                    {loc.logout}
                  </li>
                </ul>
              </>
            ) : (
              <FaArrowRightToBracket
                onClick={() => {
                  setIsShowMainMenu(false)
                  navigate(`${langUri}/login`)
                }}
                title={loc.login}
              />
            )}
          </li>
        </ul>
      </nav>

      <div className="md:hidden flex justify-end cursor-pointer relative text-3xl">
        <div onClick={toggleBurgerMenu} className="relative">
          <FaBars />
          {isUser ? (
            <FaUser className="absolute -top-[1px] -right-2 text-sm text-sb" />
          ) : null}
        </div>

        <ul
          className={` ${
            isShowBurgerMenu ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
          } absolute overflow-hidden text-lg origin-top transition-all duration-200
             z-50 top-[150%] right:auto md:right-0 left-0 md:left-auto w-[250px] bg-[#fbfcfc]
              border border-[#888] list-none rounded-md shadow-md shadow-black/30`}
        >
          <li
            className={`px-5 py-3 w-full cursor-auto ${
              isUser ? "flex" : "hidden"
            } justify-start items-center gap-2 hover:bg-[#ecf0f1] border-sky-300 border-b-2`}
          >
            <FaUser className="text-sb" />
            <p>{user.userName}</p>
            <div
              className="text-lg text-red-500 ml-auto cursor-pointer"
              onClick={toggleBurgerMenu}
            >
              <FaTimes />
            </div>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to={`${langUri}/`}>
              <p onClick={toggleBurgerMenu}>{loc.main}</p>
            </Link>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to={`${langUri}/contacts`}>
              <p onClick={toggleBurgerMenu}>{loc.contacts}</p>
            </Link>
          </li>
          <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
            <Link to={`${langUri}/map`}>
              <p onClick={toggleBurgerMenu}>{loc.map}</p>
            </Link>
          </li>
          <li
            className={`${
              !isUser ? "block" : "hidden"
            } px-5 py-1 w-full hover:bg-[#ecf0f1]`}
          >
            <div
              onClick={() => {
                toggleBurgerMenu()
                navigate(`${langUri}/login`)
              }}
            >
              <p>{loc.login}</p>
            </div>
          </li>
          <div className={isUser ? "block" : "hidden"}>
            <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
              <Link to={`${langUri}/order_user_list/${user._id}`}>
                <p onClick={toggleBurgerMenu}>{loc.my_orders}</p>
              </Link>
            </li>
            <li className="px-5 py-2 w-full hover:bg-[#ecf0f1]">
              <Link to={`${langUri}/user_profile`}>
                <p onClick={toggleBurgerMenu}>{loc.profile}</p>
              </Link>
            </li>
            <li
              className="px-5 py-2 w-full hover:bg-[#ecf0f1]"
              onClick={() => {
                toggleBurgerMenu()
                logout()
              }}
            >
              {loc.logout}
            </li>
          </div>
        </ul>
      </div>
    </>
  )
}
