const locales = {
  ru: {
    title:
      "Кармен | Купить оптом, опт, продажа. Низкие ценв, доставка по Украине",
    content_1: "Купить оптом, мелким оптом",
    content_2:
      "в магазине Кармен. Доставка с рынка Барабашово или Новой Почтой",
    name: "Название",
    description: "Описание",
    price: "Цена",
  },
  ua: {
    title:
      "Кармен | Купити оптом, опт, продаж. Низькі ціни, доставка по Україні",
    content_1: "Купити оптом, дрібним оптом",
    content_2:
      "у магазині Кармен. Доставка з ринку Барабашово або Новою Поштою",
    name: "Назва",
    description: "Опис",
    price: "Ціна",
  },
}

export default locales
