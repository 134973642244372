
export default function SelectOptions({ values, setValues }) {

  const handleClick = (value) => {

    setValues((prev) => {

      const isExist = prev.selfOptValues.some((item) => item.id === value.id)
      // если value уже есть - убираем, если нет-добавляем
      const selfOptValues = isExist
        ? prev.selfOptValues.filter((item) => item.id !== value.id)
        : [...prev.selfOptValues, value]
      // обновляем selfOpt в зависимости от наличие value в соответствующей опции
      const selfOpt = prev.options.opt
        .filter((item) => selfOptValues.some((elem) => elem.optId === item.id))      
      return { ...prev, selfOptValues, selfOpt }
    })
  }

  return (
    <div className="py-3">
      {values.options.opt       
        .map((option, i) => (
          <div
            className="flex flex-wrap text-lg py-2 gap-3 items-center"
            key={i}
          >
            <p className="italic bg-[#F5CBA7] rounded-md py-1 px-3">
              {option.ru}:
            </p>
            {values.options.optValues
              .filter((item) => item.optId === option.id)
              .sort((a, b) => (a.ru > b.ru ? 1 : -1))
              .map((value) => (
                <div
                  key={value.id}
                  className={`border border-emerald-600 py-1 px-4 rounded-md hover:cursor-pointer
                    ${
                      values.selfOptValues.some((item) => item.id === value.id)
                        ? "bg-emerald-700 text-gray-50"
                        : ""
                    }`}
                  onClick={() => handleClick(value)}
                >
                  {value.ru}
                </div>
              ))}
        
          </div>
        ))}
    </div>
  )
}
