import { useContext } from "react"
import Links from "../components/Links"
import { useNavigate } from "react-router-dom"
import AuthContext from "../context/AuthContext"
import { FaEye } from "react-icons/fa"
import { formatingDate,  isNotEmpty } from "../utils"
import { useOrderUserById } from "../swr"
import { Helmet } from "react-helmet-async"
import ShopContext from '../context/ShopContext'
import locales from "../config/locales/orderUserList"

export default function OrderUserList() {
  const { user } = useContext(AuthContext)
  const {  langKey, langHtml,langUri } = useContext(ShopContext)
 
  const loc =  locales[langKey]
 
  const orders = useOrderUserById({  id: user._id })
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className="px-2">
        <Links home={true} back={true} />
        {isNotEmpty(user) ? (
          <div className="flex justify-center pt-4 max-h-[400px] overflow-auto text-lg">
            <table className="border-collapse table-fixed w-[1024px] min-w-[640px] border border-gray-600">
              <thead>
                <tr className="text-center bg-amber-50">
                  <th className="border border-gray-600 p-1 w-[100px]">№</th>
                  <th className="border border-gray-600 p-1">{loc.date}</th>
                  <th className="border border-gray-600 p-1">
                    {loc.totalAmount}
                  </th>
                  <th className="border border-gray-600 p-1 w-[100px]">
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ? orders.map((item, i) => (
                      <tr className="text-center" key={i}>
                        <td className="border border-gray-600 p-1">
                          {item.count}
                        </td>
                        <td className="border border-gray-600 p-1">
                          {formatingDate(item.createdAt)}
                        </td>
                        <td className="border border-gray-600 p-1">
                          {item.totalAmount}
                        </td>
                        <td
                          className="border border-gray-600 p-1"
                          title="Смотреть заказ"
                        >
                          <p
                            className="inline-block align-middle text-sb cursor-pointer"
                            onClick={() => navigate(`${langUri}/order_page/${item._id}`)}
                          >
                            <FaEye />
                          </p>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </>
  )
}
