const locales = {
  ru: {
    title: "Оформлення замовлення",
    content: "Оформлення замовлення покупцем у магазині Кармен",
    sendOrder: "Отправить заказ",
    name: "Имя получателя",
    surname: "Фамилия получателя",
    phone: "Телефон",
    delivery: "Способ доставки",
    pickup: "Самовывоз",
    carrier: "Перевозчик",
    newPost: "Новая почта",
    settlement: "Населенный пункт",
    branch: "Отделение",
    paymentForm: "Форма оплаты",
    prepaid: "Предоплата",
    postpaid: "Наложенный платеж",
  },
  ua: {
    title: "",
    content: "",
    sendOrder: "Надіслати замовлення",
    name: "Ім'я отримувача",
    surname: "Прізвище отримувача",
    phone: "Телефон",
    delivery: "Спосіб доставки",
    pickup: "Самовивіз",
    carrier: "Перевізник",
    newPost: "Нова пошта",
    settlement: "Населений пункт",
    branch: "Відділення",
    paymentForm: "Форма оплати",
    prepaid: "Передплата",
    postpaid: "Післяплата",
  },
}

export default locales
