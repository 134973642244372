const locales = {
  ru: {
    title: "Кармен. Купить оптом, опт, продажа оптом, мелким оптом, Барабащово",
    descriptionAdd: "Купить оптом и в розницу, опт, розница",
    choosen: "Выбрано",
    amount: "Сумма",
    description: "Описание",
    wholesalePrice: "оптовая цена",
    toCart: "в корзину",
  },
  ua: {
    title: "Кармен. Купити оптом, опт, продаж оптом, дрібним оптом, Барабашово",
    descriptionAdd: "Купити оптом та в роздріб, опт, роздріб",
    choosen: "Вибрано",
    amount: "Сума",
    description: "Опис",
    wholesalePrice: "оптова ціна",
    toCart: "у кошик",
  },
}

export default locales
