const locales = {
  ru: {
    shop_name: "Оптовый магазин Кармен",
    catalogs: "Каталоги",
    brands: "Бренды",
    address: ' Xарьков, ТЦ Барабашово, стоянка "Болото", BK-512',
    toPrevCatalog: "К предыдущему каталогу",
    forsedOpen: "Открыть каталог с подкаталогвми",
  },
  ua: {
    shop_name: "Оптовий магазин Кармен",
    catalogs: "Каталоги",
    brands: "Бренди",
    address: 'Харків, ТЦ Барабашово, стоянка "Болото", BK-512',
    toPrevCatalog: "До попереднього каталогу",
    forsedOpen: "Відкрити каталог із підкаталогами",
  },
}

export default locales
