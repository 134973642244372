import React, { Children, useContext, useState } from "react"

import { NOIMAGE } from "../config"
import {
  FaChevronLeft,
  FaChevronRight,
  FaGoogle,
  FaImage,
  FaPaste,
  FaPencilAlt,
  FaSave,
  FaTimes,
} from "react-icons/fa"
import AuthContext from "../context/AuthContext"
import AccessDenied from "../components/AccessDenied"
import ModalDialog from "../components/ModalDialog"
import ModalImage from "../components/ModalImage"
import { toast } from "react-toastify"
import { mutate } from "swr"
import { useGallery } from "../swr"
import Links from "../components/Links"
import { saveImage } from "../handleImage"
import HandleConfirm from "../components/HandleConfirm"
import { Helmet } from "react-helmet-async"
import { cleanName, translateString } from "../utils"

export default function EditGallery() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const cards = useGallery()
  const initValues = {
    id: null,
    description: { ru: "", ua: "" },
    image: { lg: "", md: "" },
  }

  const [values, setValues] = useState(initValues)
  const [showImageUpload, setShowImageUpload] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [cardForDelete, setCardForDelete] = useState(null)

  const handleChange = (e) => {
    e.preventDefault()
    let { value, name,dataset:{lang} } = e.target
    setValues((prev) => ({ ...prev, [name]: {...prev[name],[lang]:value} }))
  }

  const handleSave = async () => {
    const res = await fetch(`/api/gallery`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else {
      mutate(`/api/gallery`)
      setValues(initValues)
    }
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
      const { lg, md } = await saveImage({ file })
      setValues((prev) => ({ ...prev, image: { lg, md } }))
      setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleDelete = async (card) => {
    const res = await fetch(`/api/gallery/${card._id}`, {
      method: "DELETE",
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else mutate(`/api/gallery`)
  }

  const handleModal = (card) => {
    setShowModal(true)
    setCardForDelete(card)
  }

  const handle = (rez) => {
    if (rez) {
      handleDelete(cardForDelete)
    }
    setCardForDelete(null)
    setShowModal(false)
  }

  const handleEditCard = (card) => {
    setValues({
      id: card._id,
      description: card.description,
      image: {
        lg: card.image.lg,
        md: card.image.md,
      },
    })
  }

  const fetchCards = async (cards) => {
    const res = await fetch(`/api/gallery/cards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cards }),
    })

    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else mutate(`/api/gallery`)
  }

  const handleImageLeft = async (i) => {
    if (i === 0) return
    const copy = cards.slice(i - 1, i + 1)
    const temp = copy[0].order
    copy[0].order = copy[1].order
    copy[1].order = temp

    await fetchCards(copy)
  }
  const handleImageRight = async (i) => {
    if (i === cards.length - 1) return
    const copy = cards.slice(i, i + 2)
    const temp = copy[0].order
    copy[0].order = copy[1].order
    copy[1].order = temp

    await fetchCards(copy)
  }

  const handleTranslate = async () => {
    if (!values.description.ru) return

    try {
      const ua = await translateString(values.description.ru)
      setValues((prev) => ({ ...prev, description: { ...prev.description, ua } }))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const copyName = () => {   
    setValues((prev) => ({
      ...prev,
      description: { ...prev.description, ua: prev.description.ru },
    }))
  }

  const handleBlur = (e) => {
    const { name, value,dataset:{lang} } = e.target
    setValues((prev) => ({ ...prev, [name]: { ...prev[name], [lang]:cleanName(value)  } }))
  }

  return (
    <>
      <Helmet>
        <title>Редактировать галереи</title>
        <meta
          name="description"
          content="Редактирование галереи в машазине Кармен"
        />
      </Helmet>

      <div className="px-2  tracking-widest">
        <Links back={true} />
        {isAdmin ? (
          <div className="grid grid-cols-1 md:grid-cols-[350px_1fr] gap-4 pt-4">
            <div className="flex flex-col gap-3 items-center">
              <div className="w-full flex justify-between items-center ">
                <h2 className="font-bold">Картинка</h2>
                <div className="flex justify-center items-center gap-3 text-2xl">
                  <FaImage
                    className="text-sb cursor-pointer"
                    title="Загрузить"
                    onClick={() => setShowImageUpload(true)}
                  />
                  <FaTimes
                    className="text-red-500 cursor-pointer"
                    title="Удалить"
                    onClick={() =>
                      setValues((prev) => ({
                        ...prev,
                        image: { lg: "", md: "" },
                      }))
                    }
                  />
                </div>
              </div>

              <div className="w-[330px] h-[420px] p-1 flex justify-center items-center border border-gray-500">
                <img
                  className="max-w-full max-h-full"
                  src={values.image.md ? `${values.image.md}` : NOIMAGE}
                  alt=""
                />
              </div>
            </div>
            <div className="px-3">
              <FaSave
                className="text-sb text-2xl justify-self-end  cursor-pointer"
                onClick={handleSave}
                title="Сохранить"
              />

              <div>
                <div className="flex justify-between items-center py-2">
                  <h2 className=" font-bold">Описание</h2>
                  <FaTimes
                    className="text-red-500 text-2xl cursor-pointer"
                    onClick={() => setValues(initValues)}
                    title="Отмена"
                  />
                </div>
                <textarea
                  className="w-full text-xl   p-3"
                  name="description"
                  data-lang="ru"
                  rows={5}
                  value={values.description.ru}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              <div>
                <h2 className="py-2 font-bold">Опис</h2>
                <div className="relative">
                  <textarea
                    className="w-full text-xl   p-3"
                    name="description"
                    data-lang="ua"
                    rows={5}
                    value={values.description.ua}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  <div
                    className="absolute top-0 right-1 bg-gray-50 text-base text-gray-700 
                                    flex items-center gap-2 px-1 -translate-y-1/2 "
                  >
                    <FaGoogle
                      className=" hover:cursor-pointer"
                      title="Google перевод"
                      onClick={handleTranslate}
                    />
                    <FaPaste
                      className=" hover:cursor-pointer"
                      title="Скопировать"
                      onClick={copyName}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2">
              <h2 className="font-bold">Галерея</h2>
              <div className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-6">
                {cards
                  ? cards.map((card, i) => (
                      <div
                        className={`relative w-[250px] h-[330px] justify-self-center p-1 border  flex justify-center
                      items-center ${
                        card._id === values.id
                          ? "border-red-500"
                          : "border-gray-500"
                      }`}
                        key={i}
                      >
                        <img
                          className="max-w-full max-h-full"
                          src={card.image.lg ? `${card.image.lg}` : NOIMAGE}
                          alt=""
                        />
                        <div
                          className="absolute top-0 left-0 -translate-y-1/2 flex justify-center
                       items-center gap-4 rounded-md px-1 py-2 bg-gray-50 text-xl"
                        >
                          <FaChevronLeft
                            className="text-sb cursor-pointer"
                            title="Сдвинуть влево"
                            onClick={() => handleImageLeft(i)}
                          />

                          <FaChevronRight
                            className="text-sb cursor-pointer"
                            title="Сдвинуть вправо"
                            onClick={() => handleImageRight(i)}
                          />
                          <FaPencilAlt
                            className="text-sb cursor-pointer"
                            title="Редактировать"
                            onClick={() => handleEditCard(card)}
                          />
                          <FaTimes
                            className="text-red-500 cursor-pointer"
                            title="Удалить"
                            onClick={() => handleModal(card)}
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>

            {showImageUpload ? (
              <ModalDialog>
                <ModalImage
                  handleUploadChange={handleUploadChange}
                  setShowImageUpload={setShowImageUpload}
                />
              </ModalDialog>
            ) : null}
            {showModal ? (
              <HandleConfirm title1="Удалить товар?" handle={handle} />
            ) : null}
          </div>
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  )
}
