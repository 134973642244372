import React, { useContext } from "react"
import AdminPanel from "../components/AdminPanel"
import Showcase from "../components/Showcase"
import { Helmet } from "react-helmet-async"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/home"


export default function Home() {
   const API_URL = process.env.REACT_APP_API_URL
  const { langUri,langKey,langHtml } = useContext(ShopContext)
  const loc=locales[langKey]

  return (
    <div className="h-full grid grid-cols-1 items-center">
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href={`${API_URL}`}
        />
        <link
          rel="alternate"
          hreflang="uk"
          href={`${API_URL}/ua`}
        />
        <link
          rel="canonical"
          href={`${API_URL}${langUri}`}
        />
        <meta name="description" content={loc.content} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: loc.title,
            description: loc.description,
            url: `https://karmen.in.ua${langUri}`,
          })}
        </script>
      </Helmet>

      <AdminPanel />
      <Showcase />
    </div>
  )
}
