import React from "react"
import { stringToPrice } from "../utils"
import { FaShareAlt, FaTimes } from "react-icons/fa"


export default function AdminPriceBlock({
  optPrices,
  saveValues,
  crumbs,
  optPricesOrigin,
  closeArrPrices
}) {
  const changeHandler = (e) => {
    e.preventDefault()
    const { value, name } = e.target
    optPrices[name] = value
    saveValues()
  }

  const handleBlur = (e) => {
    let { value } = e.target
    value = stringToPrice(value)
    optPrices.price = value
    saveValues()
  }

  const shareHandler = () => {
      let values = optPricesOrigin
      const deep = (values) => {
        if (values.hasOwnProperty("price")) {
          values.price = optPrices.price
          return
        }
        values.forEach((optObj) => {
          deep(optObj.add)
        })
      }
      deep(values)
      saveValues()
  }
  const shareResetHandler = () => {
    let values = optPricesOrigin
    const deep = (values) => {
      if (values.hasOwnProperty('price')) {
        values.price = ''
        return
      }
     values.forEach((optObj) => {
       deep(optObj.add)
     })
    }
    deep(values)
    saveValues()
  }

  const shareCloseHandler = () => {   
    closeArrPrices.forEach(optObj => {
      optObj.add.price=optPrices.price
    })
    saveValues()
  }

  const shareCloseResetHandler = () => {
    closeArrPrices.forEach((optObj) => {
      optObj.add.price = ''
    })
    saveValues()
  }


  return (
    <div className="flex gap-x-3">
      <div>
        {!!crumbs.length && (
          <div className="flex items-center gap-3 text-base">
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-orange-400 cursor-pointer"
                title="Скопировать на все поля"
                onClick={shareHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить со всех полей"
                onClick={shareResetHandler}
              />
            </div>
            <div className="flex items-center justify-center gap-3 border border-gray-400 p-1 rounded-md">
              <FaShareAlt
                className=" text-green-700 cursor-pointer"
                title="Скопировать в пределах опции"
                onClick={shareCloseHandler}
              />
              <FaTimes
                className="text-red-500 text-base cursor-pointer"
                title="Удалить в пределах опции"
                onClick={shareCloseResetHandler}
              />
            </div>
          </div>
        ) }
      </div>
      <input
        className="text-center text-lg/6 w-24"
        type="text"
        id="price"
        name="price"
        placeholder="Цена"
        value={optPrices.price}
        onChange={changeHandler}
        onBlur={handleBlur}
      />
      <input
        className="text-center text-lg/6 w-40"
        type="text"
        id="barcode"
        name="barcode"
        maxLength={13}
        placeholder="Штрихкод"
        value={optPrices.barcode}
        onChange={changeHandler}
      />
    </div>
  )
}
