import React, { useContext, useEffect, useState } from "react"
import ShopContext from "../../context/ShopContext"
import { FaCaretDown, FaCircle } from "react-icons/fa"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { transformUrl } from "../../utils"

export default function Select() {
  const navigate = useNavigate()
  const { currencyShop, setCurrencyShop } = useContext(ShopContext)

  const { lang, setLang, slugFromPage } = useContext(ShopContext)

  const [showSelectCur, setShowSelectCur] = useState(false)
  const [showSelectLang, setShowSelectLang] = useState(false)

  const { pathname } = useLocation()
  const { slug } = useParams()

  const handleSelectCur = (value) => {
    setCurrencyShop(value)
    setTimeout(() => setShowSelectCur(false), 500)
  }
  // slugFromPage это объект slug из товаров или каталогов
  // если он есть, то нужно заменить slug  в pathname на в соответствии с языком
  const handleSelectLang = (value) => {
    const url = slugFromPage
      ? pathname.replace(slug, slugFromPage[value === "ua" ? "ua" : "ru"])
      : pathname
    const path = transformUrl({ url, lang: value })

    setLang(value)

    setTimeout(() => setShowSelectLang(false), 500)
    navigate(path, { replace: true })
  }

  return (
    <div className="flex text-xl justify-end items-center gap-1  sm:gap-2">
      <div className="relative sm:px-2 px-1">
        <div
          className="text-sb text-xl hover:text-sb-dark  cursor-pointer flex 
        items-center "
          onClick={() => setShowSelectLang((prev) => !prev)}
        >
          <p>{lang === "ua" ? "UA" : "RU"}</p>
          <FaCaretDown className="text-3xl" />
        </div>
        <ul
          className={`absolute  top-full right-0 z-10 w-full border border-sb p-1
         rounded-lg bg-gray-100 origin-top transition-all duration-200
          ${
            showSelectLang ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
          } `}
        >
          <li
            className={`flex justify-center items-center gap-2 sm:gap-3  hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer `}
            onClick={() => handleSelectLang("")}
          >
            <p>RU</p>
            <FaCircle
              className={`text-[10px] text-green-600 ${
                lang === "" ? "opacity-100" : "opacity-0"
              }`}
            />
          </li>

          <li
            className="flex justify-center items-center gap-2 sm:gap-3  hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleSelectLang("ua")}
          >
            <p>UA</p>
            <FaCircle
              className={`text-[10px] text-green-600 ${
                lang === "ua" ? "opacity-100" : "opacity-0"
              }`}
            />
          </li>
        </ul>
      </div>

      <div className="relative sm:px-2 px-1">
        <div
          className="text-sb text-xl hover:text-sb-dark  cursor-pointer flex 
        items-center "
          onClick={() => setShowSelectCur((prev) => !prev)}
        >
          <p>{currencyShop}</p>
          <FaCaretDown className="text-3xl" />
        </div>
        <ul
          className={`absolute  top-full right-0 z-10 w-full border border-sb p-1
         rounded-lg bg-gray-100 origin-top transition-all duration-200
          ${
            showSelectCur ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
          } `}
        >
          <li
            className={`flex justify-center items-center gap-2 sm:gap-3  hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer `}
            onClick={() => handleSelectCur("UAH")}
          >
            <p>UAH</p>
            <FaCircle
              className={`text-[10px] text-green-600 ${
                currencyShop === "UAH" ? "opacity-100" : "opacity-0"
              }`}
            />
          </li>

          <li
            className="flex justify-center items-center gap-2 sm:gap-3  hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleSelectCur("USD")}
          >
            <p>USD</p>
            <FaCircle
              className={`text-[10px] text-green-600 ${
                currencyShop === "USD" ? "opacity-100" : "opacity-0"
              }`}
            />
          </li>
          <li
            className="flex justify-center items-center gap-2 sm:gap-3 hover:bg-gray-700 hover:text-gray-50 
          transition-colors duration-300 cursor-pointer"
            onClick={() => handleSelectCur("EUR")}
          >
            <p>EUR</p>
            <FaCircle
              className={`text-[10px] text-green-600 ${
                currencyShop === "EUR" ? "opacity-100" : "opacity-0"
              }`}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}
