import React, { useContext } from "react"
import { useInformation } from "../swr"
import {  INFORMATIONS_IMAGES } from "../config"
import { Helmet } from "react-helmet-async"
import Links from "../components/Links"
import locales from "../config/locales/information"
import ShopContext from "../context/ShopContext"

export default function Information() {
   const API_URL = process.env.REACT_APP_API_URL
  const information = useInformation()
  const { langKey,langHtml,langUri } = useContext(ShopContext)
  const loc = locales[langKey]

  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <link
          rel="alternate"
          hreflang="ru"
          href={`${API_URL}/information`}
        />
        <link
          rel="alternate"
          hreflang="uk"
          href={`${API_URL}/ua/information`}
        />
        <link
          rel="canonical"
          href={`${API_URL}${langUri}/information`}
        />
        <title>{loc.title_information}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <Links back={true} home={true} />
      <div className="text-xl pt-4">
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          {loc.aboutUs}
        </h2>
        <p className="indent-6">{information?.aboutUs?.[langKey] ?? null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          {loc.conditions}
        </h2>
        <p className="indent-6">{information?.conditions?.[langKey] ?? null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          {loc.productReturn}
        </h2>
        <p className="indent-6">
          {information?.productReturn?.[langKey] ?? null}
        </p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          {loc.delivery}
        </h2>
        <p className="indent-6">{information?.delivery?.[langKey] ?? null}</p>
        <h2 className="text-2xl italic text-orange-800 font-bold text-left">
          {loc.payment}
        </h2>
        <p className="indent-6">{information?.payment?.[langKey] ?? null}</p>

        <div
          className="my-3 grid justify-items-center 
        grid-cols-[repeat(auto-fill,minmax(170px,1fr))] gap-1
        sm:grid-cols-[repeat(auto-fill,minmax(210px,1fr))] sm:gap-3
        lg:grid-cols-[repeat(auto-fill,minmax(270px,1fr))] md:gap-5
      "
        >
          {INFORMATIONS_IMAGES.map((image, i) => (
            <div
              className="w-[170px] h-227px] p-1 border border-gray-700
            sm:w-[210px] h-280px]
            lg:w-[270px] h-360px]"
              key={i}
            >
              <img
                className="max-w-full max-h-full"
                src={image}
                alt="фото магазина"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
