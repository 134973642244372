import { useNavigate } from "react-router-dom"

import { FaArrowLeft, FaHome } from "react-icons/fa"
import { useContext } from "react"
import ShopContext from "../context/ShopContext"


export default function Links({ home, back, children }) {
  const navigate = useNavigate()
  const {  langUri} = useContext(ShopContext)
 
  return (
    <div
      className="flex items-center justify-between gap-2 px-2 border border-blue-300
     rounded-md py-1"
    >
      <div className="flex items-center text-sb  gap-3 h-9">
        {back && (
          // <FaChevronCircleLeft
          <FaArrowLeft
            onClick={() => navigate(-1)}
            title="Назад"
            className="hover:text-sb-dark hover:cursor-pointer text-2xl"
          />
        ) }
        {home && (
          <FaHome
            onClick={() => navigate(`${langUri}/`)}
            title="На главную"
            className="hover:text-sb-dark hover:cursor-pointer text-3xl"
          />
        )}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  )
}
