const locales = {
  ru: {
    title: "Заказ",
    content: "Страница заказа",
    order: "Заказ №",
    from: "от",
    name: "Имя:",
    surname: "Фамилия:",
    phone: "Телефон:",
    city: "Город:",
    delivery: "Способ доставки:",
    paid: "Способ оплаты:",
    model: "Модель",
    price: "Цена",
    amount: "Кол-во",
    totalQnt: "Всего товаров:",
    totalAmount: "Сумма:",
  },
  ua: {
    title: "Замовлення",
    content: "Сторінка замовлення",
    order: "Замовлення №",
    from: "від",
    name: "Ім'я:",
    surname: "Прізвище:",
    phone: "Телефон:",
    city: "Місто:",
    delivery: "Спосіб доставки:",
    paid: "Спосіб оплати:",
    model: "Модель",
    price: "Ціна",
    amount: "Кількість",
    totalQnt: "Усього товарів:",
    totalAmount: "Сума:",
  },
}

export default locales
