import { useState, useEffect, useContext } from "react"
import {  toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import ShopContext from '../context/ShopContext'
import AccessDenied from "../components/AccessDenied"
import { formatingPhone } from "../utils/index"
import { FaUser } from "react-icons/fa"
import { Helmet } from "react-helmet-async"
import locales from "../config/locales/userProfile"

export default function UserProfile() {
  const navigate = useNavigate()
  const { user, setUser } = useContext(AuthContext)
   const {  langKey, langHtml } = useContext(ShopContext)
   const loc =  locales[langKey]
  const [userPhone, setUserPhone] = useState("+38 (")
  const [delivery, setDelivery] = useState({
    name: "",
    surname: "",
    phone: "+38 (",
    city: "",
    carrier: "",
    branch: "",
  })
  
  useEffect(() => {
    if (Object.keys(user).length) {
      setUserPhone(user.phone)
      const { name, surname, phone, carrier, branch, city } = user.delivery

      setDelivery(prev=>({ ...prev, name, surname, phone, carrier, branch, city }))
    }
  }, [user])

  const submitHandler = async (e) => {
    e.preventDefault()

    const res = await fetch(`/api/user`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"       
      },
      body: JSON.stringify({ delivery, userPhone }),
    })
    const newUser = await res.json()

    if (!res.ok) {
      toast.error(newUser.message)
      return
    }
    setUser(newUser)
    navigate(-1)
  }
  const handleDelivery = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    setDelivery({ ...delivery, [name]: value })
  }
 
  return (
    <>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <div className="px-2 h-full flex flex-col gap-4">
        <Links home={true} back={true} />

        {Object.keys(user).length ? (
          <div className="w-full flex-1 flex justify-center items-center">
            <form
              className="w-[400px] p-4 border border-gray-600 bg-gray-100 text-lg shadow-md"
              onSubmit={submitHandler}
            >
              <div className="flex items-center gap-x-3 py-2 border-b border-sb">
                <div className="flex-1 flex items-center gap-x-2">
                  <FaUser className="text-3xl text-sb" />
                  <p className="text-2xl text-gray-600">{user.userName}</p>
                </div>

                {user.isAdmin ? (
                  <p className="text-green-700 text-xl">Admin</p>
                ) : null}
              </div>
              <p className="pt-2 ">
                {loc.authMethod}
                {user.authMethod}
              </p>

              <label className="block py-1" htmlFor="userPhone">
                {loc.userPhone}
              </label>
              <input
                className="w-full h-[30px] px-1"
                type="text"
                value={userPhone}
                id="userPhone"
                name="userPhone"
                onChange={(e) => {
                  setUserPhone(formatingPhone(e.target.value))
                }}
              />

              <p className="pt-2">{loc.delivery}</p>
              <div className="border border-gray-500 rounded-md p-2">
                <label className="py-1 block" htmlFor="name">
                  {loc.name}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.name}
                  maxLength="20"
                  id="name"
                  name="name"
                  onChange={handleDelivery}
                />

                <label className="py-1 block" htmlFor="surname">
                  {loc.surName}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.surname}
                  maxLength="20"
                  id="surname"
                  name="surname"
                  onChange={handleDelivery}
                />

                <label className="py-1 block" htmlFor="phone">
                  {loc.phone}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.phone}
                  id="phone"
                  name="phone"
                  onChange={(e) =>
                    setDelivery({
                      ...delivery,
                      phone: formatingPhone(e.target.value),
                    })
                  }
                />

                <label className="py-1 block" htmlFor="city">
                  {loc.city}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.city}
                  maxLength="20"
                  id="city"
                  name="city"
                  onChange={handleDelivery}
                />

                <label className="py-1 block" htmlFor="carrier">
                  {loc.carrier}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.carrier}
                  maxLength="20"
                  id="carrier"
                  name="carrier"
                  onChange={handleDelivery}
                />

                <label className="py-1 block" htmlFor="branch">
                  {loc.branch}
                </label>
                <input
                  className="w-full h-[30px] px-1"
                  type="text"
                  value={delivery.branch}
                  maxLength="3"
                  id="branch"
                  name="branch"
                  onChange={handleDelivery}
                />
              </div>
              <div className="flex items-center justify-center py-2 ">
                <div className="border-2 border-sb p-0.5 rounded-xl">
                  <button
                    className="py-1 px-3 rounded-xl bg-sb text-slate-50 shadow-inner
                  cursor-pointer disabled:opacity-50 disabled:cursor-auto"
                    disabled={!userPhone}
                  >
                    {loc.save}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <AccessDenied />
        )}
      </div>
    </>
  )
}
