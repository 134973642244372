
import { Helmet } from "react-helmet-async"
import Links from "../components/Links"
import MapFrame from "../components/MapFrame"
import { useContext } from "react"
import ShopContext from "../context/ShopContext"
import locales from "../config/locales/map"


export default function Map() {
   const API_URL = process.env.REACT_APP_API_URL
  const { langKey,langHtml,langUri } = useContext(ShopContext)  
    const loc = locales[langKey]
  return (
    <div className="flex flex-col gap-4 max-h-full h-full">
      <Helmet>
        <html lang={langHtml} />
        <link rel="alternate" hreflang="ru" href={`${API_URL}/map`} />
        <link rel="alternate" hreflang="uk" href={`${API_URL}/ua/map`} />
        <link rel="canonical" href={`${API_URL}${langUri}/map`} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>
      <Links back={true} home={true} />
      <div className="flex-1">
        <MapFrame />
      </div>
    </div>
  )
}
