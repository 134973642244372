const locales = {
  ru: {
    title: "Корзина",
    content: "Корзина покупок в магазине Кармен",
    next: "Далее",
    model: "Модель",
    price: "Цена",
    qnt: "Кол-во",
    totalQnt: "Всего товаров",
    totalAmount: "Сумма заказа",
  },
  ua: {
    title: "Кошик",
    content: "Кошик покупок у магазині Кармен",
    next: "Далі",
    model: "Модель",
    price: "Ціна",
    qnt: "Кіл-ть",
    totalQnt: "Усього товарів",
    totalAmount: "Сума замовлення",
  },
}

export default locales
