const locales = {
  ru: {
    title_information: "Информация",
    content: "Информация о магазине Кармен",
    aboutUs: "О магазине",
    conditions: "Сотрудничество",
    productReturn: "Возврат товара",
    delivery: "Доставка",
    payment: "Цены и оплата",
  },
  ua: {
    title_information: "Інформація",
    content: "Інформація про магазин Кармен",
    aboutUs: "Про магазин",
    conditions: "Співробітництво",
    productReturn: "Повернення товару",
    delivery: "Доставка",
    payment: "Ціни та оплата",
  },
}

export default locales
