import { useContext, useEffect, useState } from "react"
import ShopContext from "../context/ShopContext"
import { getCurrencySymbol,  getQntInCart, getTotalAmount } from "../utils/index"
import Links from "../components/Links"
import { useNavigate } from "react-router-dom"
import { FaTimes } from "react-icons/fa"
import { Helmet } from "react-helmet-async"
import { useCurrencyRate } from "../swr"
import { toast } from "react-toastify"
import locales from "../config/locales/cart"

export default function Cart() {
  const { cart, setCart, langKey,langHtml,langUri } = useContext(ShopContext)
  

  const loc = locales[langKey]
  const [isDisable, setIsDisable] = useState(false)
  const navigate = useNavigate()

  const currencyRate = useCurrencyRate()

  useEffect(() => {
    setIsDisable(!getQntInCart(cart))
  }, [cart])
  // optionList собирает все возможные опции из корзины

  const optionList = cart.length
    ? cart.reduce((acc, item) => {
        const itemOptions = item.options.map((optObj) => optObj[langKey])
        itemOptions.forEach((option) => {
          if (!acc.includes(option)) acc.push(option)
        })
        return acc
      }, [])
    : []

  const handleClick = () => {
    if (!currencyRate) {
      toast.error("Отсутствуют данные о курсе валют")
      return
    }

    const totalAmountInUAH = cart
      .reduce(
        (acc, item) =>
          acc + item.price * currencyRate[item.currencyValue] * item.qnt,
        0
      )
      .toFixed(2)
    if (totalAmountInUAH > 1000) {
      navigate(`${langUri}/checkout`)
    } else {
      toast.warning(
        "Минимальная сумма заказа 1000грн( см. раздел Информация). Для покупки в розницу свяжитесь, пожалуйста, с продавцом по телефону, указанному на сайте"
      )
    }
  }
 
  return (
    <div>
      <Helmet>
        <html lang={langHtml} />
        <title>{loc.title}</title>
        <meta name="description" content={loc.content} />
      </Helmet>

      <Links home={true} back={true}>
        <div className="flex justify-end ">
          <div className="p-0.5 border-2 border-sb rounded-lg">
            <button
              className="text-base text-gray-50 bg-sb px-4 py-1 rounded-lg
            disabled:opacity-50 disabled:cursor-auto hover:bg-sb-dark"
              onClick={handleClick}
              disabled={isDisable}
            >
              {loc.next}
            </button>
          </div>
        </div>
      </Links>

      <div className="px-3 pt-4 text-lg overflow-auto" id="table">
        <table className=" table-fixed w-full border-collapse min-w-[768px] ">
          <thead className="text-center">
            <tr className="border border-gray-600">
              <td>{loc.model}</td>
              {optionList.length
                ? optionList.map((item, i) => <td key={i}>{item}</td>)
                : null}
              <td className="w-[100px]">{loc.price}</td>
              <td className="w-[100px]">{loc.qnt}</td>
              <td className="w-[100px]">
                <FaTimes
                  className="inline text-red-500 text-xl cursor-pointer"
                  title="Очистить корзину"
                  onClick={() => setCart([])}
                />
              </td>
            </tr>
          </thead>
          <tbody className="text-center">
            {cart && cart.length ? (
              cart.map((item, i) => (
                <tr className="border border-gray-600" key={i}>
                  <td>{item.name[langKey]}</td>
                  {optionList.length
                    ? optionList.map((option, i) => (
                        <td key={i}>
                          {item.options.find(
                            (optObj) => optObj[langKey] === option
                          )?.value[langKey] ?? ""}
                        </td>
                      ))
                    : null}
                  <td>
                    {item.price}&nbsp;{getCurrencySymbol(item.currencyValue)}
                  </td>
                  <td>{item.qnt}</td>
                  <td>
                    <FaTimes
                      className="inline text-red-500 text-xl cursor-pointer"
                      title="Удалить строку"
                      onClick={() =>
                        setCart(cart.filter((item, idx) => i !== idx))
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border border-gray-600">
                <td>&nbsp;</td>
              </tr>
            )}
            <tr className="border border-gray-600">
              <td colSpan={optionList.length + 4}>
                <div className="flex justify-between px-1 bg-gray-100">
                  <p>
                    {loc.totalQnt}: {cart ? getQntInCart(cart) : ""}
                  </p>
                  <p>
                    {loc.totalAmount}: {cart ? getTotalAmount(cart) : ""}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
