import AccessDenied from "../components/AccessDenied"
import AuthContext from "../context/AuthContext"
import { useContext, useState, useEffect } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom"
import { cleanName, createOptPrices, idToString, isNotEmpty, translateString } from "../utils/index"
import SelectOptions from "../components/SelectOptions"
import Links from "../components/Links"
import ModalImage from "../components/ModalImage"

import {
  FaArrowAltCircleRight,
  FaArrowCircleLeft,
  FaCircle,
  FaCloudDownloadAlt,
  FaGoogle,
  FaPaste,
  FaPlusSquare,
  FaSave,
  FaTimes,
  FaWindowClose,
} from "react-icons/fa"

import ModalDialog from "../components/ModalDialog"
import { useBrands, useCatalogs, useOptions } from "../swr"

import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"
import AdminPrice from "../components/AdminPrice"
import { useSWRConfig } from "swr"
import BrandContext from "../context/BrandContext"

export default function EditProduct() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const { mutate } = useSWRConfig()
  const { brands: brandsContext } = useContext(BrandContext)
  const product = useLoaderData() // with populate!!!
console.log(product)
  const navigate = useNavigate()
  const {revalidate}=useRevalidator()

  const brands = useBrands()
  const catalogs = useCatalogs()

  const options = useOptions()

  const [values, setValues] = useState({
    _id: product._id,
    name: product.name,
    description: product.description,
    brand: product.brandId??null,
    catalog: product.catalogId??null,
    options: product.optionsId ?? null,  
    selfOpt: product.selfOpt,
    selfOptValues:product.selfOptValues,  
    optPrices: product.optPrices,  
    isShowcase: product.isShowcase,
    currencyValue: product.currencyValue,
  })

  const [images, setImages] = useState(product.images) 
  const [imageIdx, setImageIdx] = useState(0)
  const [showImageUpload, setShowImageUpload] = useState(false)

  const listForCatalogMenu = catalogs
    ? catalogs.sort((a, b) => (a.tree.ru > b.tree.ru ? 1 : -1))
    : []
  // следующие два useEffect нужны для обновления product через loader при изменении options и 
  // затем для обновления values
  useEffect(() => {  
    setValues((prev) => ({
      ...prev,
      options: product.optionsId ?? null,
      selfOpt: product.selfOpt,
      selfOptValues: product.selfOptValues,
    }))
  },[product])
  
  useEffect(() => {
    if (!options) return   
   revalidate()
  },[options])

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      optPrices: createOptPrices(prev),
    }))
  }, [values.selfOptValues])

  const resetBrand = () => { 
    setValues((prev) => ({ ...prev, brand: null }))
  }

  const resetCatalog = () => {  
    setValues((prev) => ({ ...prev, catalog: null }))
  }

  const resetOptions = () => { 
    setValues((prev) => ({ ...prev, options: null,selfOpt:[],selgOptValues:[] }))
   
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Проверка на заполнение поля имени и модели товара
     const hasEmptyFields = !values.name.ru.trim() && !values.name.ua.trim()

    if (hasEmptyFields) {
      toast.error("Поле Название должно быть заполнено")
      return
    }

    if (!values.brand) {
      toast.error("Поле бренда является обязательным")
      return
    }
    if (!values.catalog) {
      toast.error("Поле каталога является обязательным")
      return
    }

    // Send data
    const res = await fetch(`/api/products`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
        catalogId: values.catalog?._id ?? null,
        brandId: values.brand?._id ?? null,
        optionsId: values.options?._id ?? null,
        images,
      }),
    })
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
    } else {
      const brandIds = brandsContext.map((item) => item._id)
      mutate({ url: `/api/catalogs/navbar`, swrData: { brandIds } })
      navigate(-2)
    }
  }

  const handleChange = (e) => {
    const {
      name,
      value,
      dataset: { lang },
    } = e.target
    e.preventDefault()
    // ввод названия товара
    setValues((prev) => ({ ...prev, [name]: { ...prev[name], [lang]: value } }))
  }
  const handleOptionsClick = (item) => {  
    setValues((prev) => ({ ...prev, options: item }))
  
  }

  const handleClickBrand = (item) => {
    setValues((prev) => ({ ...prev, brand: item }))
   
  }

  const handleClickCatalog = (item) => {
    //  проверка на подкаталоги
    if (!item.isLeaf) {
      toast.warning(
        "В выбранной каталогк есть подкаталоги. Выберите другой каталог"
      )
      return
    }
    setValues((prev) => ({ ...prev, catalog: item }))
  
  }

  const handleTranslateName = async () => {
    if (!values.name.ru) return

    try {
      const ua = await translateString(values.name.ru)
      setValues((prev) => ({ ...prev, name: { ...prev.name, ua } }))
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleTranslateDescription = async () => {
    if (!values.description.ru) return

    try {
      const ua = await translateString(values.description.ru)
      setValues((prev) => ({
        ...prev,
        description: { ...prev.description, ua },
      }))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const copyName = () => {
    setValues((prev) => ({ ...prev, name: { ...prev.name, ua: prev.name.ru } }))
  }
  const copyDescription = () => {
    setValues((prev) => ({
      ...prev,
      description: { ...prev.description, ua: prev.description.ru },
    }))
  }

  const handleBlur = (e) => {
    const {
      name,
      value,
      dataset: { lang },
    } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: { ...prev[name], [lang]: cleanName(value) },
    }))
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
      const { lg, md, sm } = await saveImage({ file })
      // что нажато: добавление новой картинки или изменение существующей
      if (images[imageIdx]) {
        setImages(
          images.map((item, i) => (i === imageIdx ? { lg, md, sm } : item))
        )
      } else {
        setImages([...images, { lg, md, sm }])
      }

      setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
  }
  const deleteImage = (i) => {
    setImages(images.filter((item, idx) => idx !== i))
  }

  const handleImageLeft = (i) => {
    if (i === 0) return
    const newImages = [...images]
    newImages[i] = images[i - 1]
    newImages[i - 1] = images[i]
    setImages(newImages)
  }
  const handleImageRight = (i) => {
    if (i === images.length - 1) return
    const newImages = [...images]
    newImages[i] = images[i + 1]
    newImages[i + 1] = images[i]
    setImages(newImages)
  }

  return (
    <>
      <Helmet>
        <title>Редактировать товар</title>
        <meta
          name="description"
          content="Редактирование товара в машазине Кармен"
        />
      </Helmet>
      {!isAdmin ? (
        <AccessDenied />
      ) : (
        <div>
          <div className="px-2">
            <form>
              <Links home={true} back={true}>
                <div className="flex justify-end items-center text-3xl">
                  <FaSave
                    className="text-sb cursor-pointer hover:text-sb-dark"
                    title="Сохранить"
                    name="save"
                    onClick={handleSubmit}
                  />
                </div>
              </Links>

              <div className="grid grid-cols-[1fr_1fr_150px]  gap-5 py-5">
                <div>
                  <input
                    placeholder="Название RU"
                    className="block w-full h-8 px-1 py-2 text-lg"
                    type="text"
                    name="name"
                    data-lang="ru"
                    value={values.name.ru}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="relative">
                  <input
                    placeholder=" Название UA"
                    className="block w-full h-8 px-1 py-2 text-lg"
                    type="text"
                    name="name"
                    data-lang="ua"
                    value={values.name.ua}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="absolute top-0 right-1 bg-gray-50 flex items-center gap-2 px-2 -translate-y-1/2 ">
                    <FaGoogle
                      className="text-base text-gray-700 hover:cursor-pointer"
                      title="Google перевод"
                      onClick={handleTranslateName}
                    />
                    <FaPaste
                      className="text-base text-gray-700 hover:cursor-pointer"
                      title="Скопировать"
                      onClick={copyName}
                    />
                  </div>
                </div>

                <div
                  className="relative justify-self-center text-lg w-full cursor-pointer
                 bg-gray-50 border rounded-md border-gray-700 group/currency self-end"
                >
                  <p className="text-center p-0.5">{values.currencyValue}</p>
                  <ul className="droplist_wrapper group-hover/currency:block">
                    <div className="droplist">
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues((prev) => ({
                            ...prev,
                            currencyValue: "UAH",
                          }))
                        }
                      >
                        UAH
                      </li>
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues((prev) => ({
                            ...prev,
                            currencyValue: "USD",
                          }))
                        }
                      >
                        USD
                      </li>
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues((prev) => ({
                            ...prev,
                            currencyValue: "EUR",
                          }))
                        }
                      >
                        EUR
                      </li>
                    </div>
                  </ul>
                </div>

                <div className="relative group/category">
                  <input
                    placeholder="Бренд"
                    className="block w-full h-8 px-1 py-2 text-lg"
                    type="text"
                    value={values.brand?.name.ru??''}
                    readOnly
                  />
                  <div
                    className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                    onClick={resetBrand}
                  >
                    <FaTimes />
                  </div>

                  <ul
                    className="droplist_wrapper
                    group-hover/category:block"
                  >
                    {brands && (
                      <div className="droplist">
                        {brands.map((item, i) => (
                          <li
                            className={`px-2 cursor-pointer hover:bg-gray-100 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.brand?._id??null)
                                    ? "bg-gray-200"
                                    : ""
                                }`}
                            key={i}
                            onClick={() => handleClickBrand(item)}
                          >
                            {item.name.ru}
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </div>

                <div>
                  <div className="relative group/catalog">
                    <input
                      placeholder="Каталог"
                      className="block w-full h-8 px-1 py-2 text-lg"
                      type="text"
                      name="catalog"
                      value={values.catalog?.name.ru??''}
                      readOnly
                    />
                    <div
                      className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                      onClick={resetCatalog}
                    >
                      <FaTimes />
                    </div>

                    <ul
                      className="droplist_wrapper
                    group-hover/catalog:block"
                    >
                      {listForCatalogMenu.length && (
                        <div className="droplist">
                          {listForCatalogMenu.map((item, i) => (
                            <li
                              className={`px-2 flex items-center gap-2 cursor-pointer hover:bg-gray-200 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.catalog?._id??null)
                                    ? "bg-gray-200"
                                    : ""
                                }
                                ${!item.isLeaf ? "pointer-events-none" : ""} `}
                              key={i}
                              onClick={() => handleClickCatalog(item)}
                            >
                              <p>{item.tree.ru}</p>
                              {!item.isLeaf && (
                                <FaCircle className="text-xs text-red-500" />
                              )}
                            </li>
                          ))}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="place-self-end justify-self-stretch">
                  <input
                    className="appearance-none outline-none peer "
                    type="checkbox"
                    checked={values.isShowcase}
                    id="isShowcase"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        isShowcase: e.target.checked,
                      }))
                    }
                  />
                  <label
                    className=" text-lg border border-gray-700 rounded-md  w-full
                     h-8 text-center inline-block bg-gray-50 peer-checked:bg-emerald-700
                    cursor-pointer peer-checked:text-gray-50 "
                    htmlFor="isShowcase"
                  >
                    На витрине
                  </label>
                </div>

                <div className="relative group/options">
                  <input
                    placeholder="Опции"
                    className="block w-full h-8 px-1 py-2 text-lg"
                    type="text"
                    name="options"
                    value={values.options?.name??''}
                    readOnly
                  />
                  <div
                    className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                    onClick={resetOptions}
                  >
                    <FaTimes />
                  </div>

                  <ul className="droplist_wrapper  group-hover/options:block">
                    {options ? (
                      <div className="droplist">
                        {options.map((item, i) => (
                          <li
                            className={`px-2 cursor-pointer hover:bg-gray-100 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.options?._id??null)
                                    ? "bg-gray-200"
                                    : ""
                                }`}
                            key={i}
                            onClick={() => handleOptionsClick(item)}
                          >
                            {item.name}
                          </li>
                        ))}
                      </div>
                    ) : null}
                  </ul>
                </div>
              </div>
              {values.options ? (
                <SelectOptions
                  values={values}
                  setValues={setValues}                 
                />
              ) : null}

              {isNotEmpty(values.optPrices) ? (
                <AdminPrice values={values} setValues={setValues} />
              ) : null}
              <div className="pt-5">
                <textarea
                  placeholder="Описание товара RU"
                  className="block w-full h-[100px] px-2   text-lg indent-6"
                  type="text"
                  name="description"
                  data-lang="ru"
                  value={values.description.ru}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              <div className="relative pt-5">
                <textarea
                  placeholder="Описание товара UA"
                  className="block w-full h-[100px] px-2   text-lg indent-6"
                  type="text"
                  name="description"
                  data-lang="ua"
                  value={values.description.ua}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                <div className="absolute top-5 right-1 bg-gray-50 flex items-center  gap-2 px-2 -translate-y-1/2 ">
                  <FaGoogle
                    className="text-base text-gray-700 hover:cursor-pointer"
                    title="Google перевод"
                    onClick={handleTranslateDescription}
                  />
                  <FaPaste
                    className="text-base text-gray-700 hover:cursor-pointer"
                    title="Скопировать"
                    onClick={copyDescription}
                  />
                </div>
              </div>
            </form>
            <div className="py-2">
              <h2>Изображения</h2>
              <div className="flex flex-row justify-start flex-wrap items-center gap-x-3">
                {images.length
                  ? images.map((item, i) => (
                      <div
                        className="flex flex-col items-start my-2 w-[200px]"
                        key={i}
                      >
                        <div
                          className="w-[200px] h-[250px] flex justify-center items-center border 
                        border-gray-700 p-1 "
                        >
                          <img
                            className="max-w-full max-h-full"
                            src={`${item.md}`}
                            alt=""
                          />
                        </div>
                        <div className="flex justify-around w-full my-2">
                          <FaArrowCircleLeft
                            className=" text-3xl cursor-pointer text-gray-400"
                            name="left"
                            title="Сдвинуть влево"
                            onClick={() => handleImageLeft(i)}
                          />
                          <FaArrowAltCircleRight
                            className=" text-3xl cursor-pointer text-gray-400"
                            name="right"
                            title="Сдвинуть вправо"
                            onClick={() => handleImageRight(i)}
                          />
                          <FaCloudDownloadAlt
                            className=" text-3xl cursor-pointer text-sb"
                            name="download"
                            title="Загрузить"
                            onClick={() => {
                              setImageIdx(i)
                              setShowImageUpload(true)
                            }}
                          />
                          <FaWindowClose
                            className=" text-3xl cursor-pointer text-red-500"
                            name="delete"
                            title="Удалить"
                            onClick={() => deleteImage(i)}
                          />
                        </div>
                      </div>
                    ))
                  : null}
                <FaPlusSquare
                  className="text-3xl cursor-pointer text-sb"
                  onClick={() => {
                    setImageIdx(images.length)

                    setShowImageUpload(true)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showImageUpload ? (
        <ModalDialog>
          <ModalImage
            handleUploadChange={handleUploadChange}
            setShowImageUpload={setShowImageUpload}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
