import { createContext, useEffect, useState } from "react"
import { getLangObj, localStorageService } from "../utils"

const ShopContext = createContext()

export const ShopProvider = ({ children, langURL }) => {
  const [currencyShop, setCurrencyShop] = useState("UAH")
  const [slugFromPage, setSlugFromPage] = useState(null)
  const [isTabActive, setIsTabActive] = useState(!document.hidden)
  const [cart, setCart] = useState(() => {
    const cartExpired = localStorageService.get("cartExpired") ?? 0
    return cartExpired < Date.now() ? [] : localStorageService.get("cart") ?? []
  })

  const [lang, setLang] = useState(langURL)
  

  const { langKey, langUri, langHtml } = getLangObj(lang)

  useEffect(() => {
    // при первом рендере и просроченой карте карта в localStorage устанавливается []
    const cartExpired = localStorageService.get("cartExpired") ?? 0
    if (cartExpired < Date.now()) {
      localStorageService.set("cart", [])
    }
  }, [])

  useEffect(() => {
    const localCart = localStorageService.get("cart")
    if (JSON.stringify(localCart) !== JSON.stringify(cart)) {
      setCart(localStorageService.get("cart") || [])
    }
  }, [isTabActive])

  useEffect(() => {
    // при изменении карты устанавливается ее время жизни/исключая первый рендер
    const localCart = localStorageService.get("cart")
    if (JSON.stringify(localCart) !== JSON.stringify(cart)) {
      const timeExpired = Date.now() + 1000 * 3600 * 48
      localStorageService.set("cartExpired", timeExpired)
      localStorageService.set("cart", cart)
    }
  }, [cart])

  useEffect(() => {
    const handleChange = () => {
      setIsTabActive(!document.hidden)
    }
    document.addEventListener("visibilitychange", handleChange)
    return () => {
      document.removeEventListener("visibilitychange", handleChange)
    }
  }, [])

  return (
    <ShopContext.Provider
      value={{
        currencyShop,
        setCurrencyShop,
        cart,
        setCart,
        lang,
        langUri,
        langKey,
        langHtml,
        setLang,
        slugFromPage,
        setSlugFromPage       
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export default ShopContext
